.section {
    &-news {
        padding: 9.5rem 2.7rem;

        @media screen and (max-width: $smartphone-middle) {
            padding: 9.5rem 1.5rem;
        }

        p {
            margin-top: 3rem;

            &:first-child {
                margin-top: 0;
            }

            .bold {
                font-family: $font-family-base-bold;
            }
        }
    }

    &-services {
        margin: 2rem 0 10.1rem;
        padding: 9.5rem 2.7rem;
        position: relative;

        @media screen and (max-width: $smartphone-middle) {
            margin: 0 0 6rem;
            padding: 5.4rem 1.5rem 6rem;
        }

        &:before {
            background-color: rgba($bg, .3);
            border-radius: 1.6rem;
            content: '';
            display: block;
            width: 150rem;
            height: 100%;
            transform: skew(27deg);
            position: absolute;
            top: 0;
            right: 48.5%;
            z-index: 1;

            @media screen and (max-width: $hd) {
                right: 51.5%;
            }

            @media screen and (max-width: $tablet) {
                display: none;
            }
        }

        &:after {
            background-color: rgba($bg, .3);
            border-radius: 1.6rem;
            content: '';
            display: block;
            width: 150rem;
            height: 100%;
            transform: skew(27deg);
            position: absolute;
            top: 0;
            left: 52.5%;
            z-index: 1;

            @media screen and (max-width: $hd) {
                left: 49.5%;
            }

            @media screen and (max-width: $tablet) {
                display: none;
            }
        }

        .container {
            position: relative;
            z-index: 3;
        }

        &_left {
            line-height: 1.5;
            padding-right: 10rem;

            @media screen and (max-width: $tablet-small) {
                padding-right: 0;
            }

            p {
                font-size: 2.2rem;
                line-height: 1.45;
                margin-bottom: 3.2rem;
                max-width: 47.6rem;

                @media screen and (max-width: $tablet) {
                    max-width: none;
                }

                @media screen and (max-width: $smartphone-middle) {
                    font-size: 1.8rem;
                    margin-bottom: 2.4rem;
                }
            }

            .text {
                color: rgba($text, .7);
                margin-bottom: 4.8rem;
                max-width: 47.6rem;

                @media screen and (max-width: $tablet) {
                    max-width: none;
                }
            }

            .btn {
                width: 35.8rem;

                @media screen and (max-width: $tablet) {
                    display: none;
                }
            }
        }

        &_right {
            padding: 1.3rem 0 0 4.2rem;

            @media screen and (max-width: $hd) {
                padding-left: 0;
            }

            @media screen and (max-width: $tablet) {
                position: relative;

                &:before {
                    background-color: rgba($bg, .3);
                    border-radius: 1.6rem;
                    content: '';
                    display: block;
                    width: 190rem;
                    height: calc(100% + 12rem);
                    transform: skew(-59deg);
                    position: absolute;
                    bottom: -9.5rem;
                    left: -34.2rem;
                    z-index: 0;
                }
            }

            @media screen and (max-width: $smartphone-middle) {
                &:before {
                    height: calc(100% + 8rem);
                    left: -44.2rem;
                    bottom: -6rem;
                }
            }

            .caption {
                font-family: $font-family-base-medium;
                font-size: 2.2rem;
                line-height: 1.09;
                margin-bottom: 8rem;

                @media screen and (max-width: $tablet) {
                    display: none;
                }
            }

            .wish {
                &-one {
                    display: flex;
                    margin: 0 0 4.7rem 8.6rem;
                    position: relative;

                    @media screen and (max-width: $hd) {
                        margin-left: 4.6rem;
                    }

                    @media screen and (max-width: $tablet) {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-left: 0;
                        text-align: center;
                    }

                    .number {
                        color: rgba($sand3, .8);
                        font-family: $font-family-base-bold;
                        font-size: 9.6rem;
                        line-height: .25;
                        position: absolute;
                        top: 1.6rem;
                        left: -2.5rem;

                        @media screen and (max-width: $tablet) {
                            margin-left: -2.5rem;
                            left: 50%;
                            transform: translateX(-50%)
                        }
                    }

                    .icon-hands {
                        margin-right: 2.1rem;
                        position: relative;

                        @media screen and (max-width: $tablet) {
                            margin: 0 0 2.4rem;
                        }
                    }

                    .text {
                        max-width: 14.2rem;
                        position: relative;
                    }
                }

                &-two {
                    display: flex;
                    margin-left: 17.4rem;
                    position: relative;

                    @media screen and (max-width: $hd) {
                        margin-left: 12.4rem;
                    }

                    @media screen and (max-width: $tablet) {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin: 0 0 4rem;
                        text-align: center;
                    }

                    .number {
                        color: rgba($sand3, .8);
                        font-family: $font-family-base-bold;
                        font-size: 9.6rem;
                        line-height: .25;
                        position: absolute;
                        top: .7rem;
                        left: -4.7rem;

                        @media screen and (max-width: $tablet) {
                            margin-left: -2.7rem;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }

                    .icon-schedule {
                        margin-right: 2.1rem;
                        position: relative;
                        flex: 0 0 4.1rem;

                        @media screen and (max-width: $tablet) {
                            flex: 0 0 4.8rem;
                            margin: 0 0 2.8rem;
                        }
                    }

                    .text {
                        max-width: 26.4rem;
                        position: relative;
                    }
                }
            }

            .or-row {
                color: $sand3;
                font-family: $font-family-base-medium;
                font-size: 2.2rem;
                line-height: 1.09;
                margin: 0 0 4.1rem 9.5rem;
                position: relative;

                @media screen and (max-width: $hd) {
                    margin-left: 7.5rem;
                }

                @media screen and (max-width: $tablet) {
                    margin-left: 0;
                    text-align: center;
                }

                &:after {
                    background-color: rgba($sand2, .6);
                    content: '';
                    display: block;
                    width: 100%;
                    height: .1rem;
                    position: absolute;
                    left: 0;
                    top: 1.2rem;
                    z-index: 1;
                }

                span {
                    background-color: $bg-input;
                    display: inline-block;
                    padding-right: 1.5rem;
                    position: relative;
                    z-index: 3;

                    @media screen and (max-width: $tablet) {
                        padding: 0 2rem;
                    }
                }
            }

            .btn {
                display: none;

                @media screen and (max-width: $tablet) {
                    display: inline-block;
                    width: 40rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                @media screen and (max-width: $smartphone-middle) {
                    width: 100%;
                    max-width: 27.3rem;
                }
            }
        }

        h2 {
            margin-bottom: 4.8rem;

            @media screen and (max-width: $smartphone-middle) {
                margin-bottom: 2.4rem;
            }
        }
    }

    &-carousel {
        margin-bottom: 9.4rem;
        display: flex;
        position: relative;

        .owl-nav {
            position: absolute;
            top: 0;
            right: -18rem;
            height: 100%;
            direction: ltr;

            &:before {
                background-color: $white;
                content: '';
                display: block;
                width: 70rem;
                height: 100%;
                position: absolute;
                top: 0;
                left: -4.3rem;
                transform: skew(-22deg);
            }

            @media screen and (max-width: $tablet-small - 67) {
                right: -15rem;

                &:before {
                    left: -1.3rem;
                }
            }

            @media screen and (max-width: $smartphone-middle) {
                width: 5rem;

                &:before {
                    left: -9.3rem;
                }

                button.owl {
                    &-prev {
                        display: none;
                    }

                    &-next {
                        position: absolute;
                        bottom: 0;
                        left: -10.5rem;
                    }
                }
            }
        }

        &_left {
            flex: 0 0 73%;
            width: 73%;
            padding-right: 3rem;

            @media screen and (max-width: $smartphone-middle) {
                flex: 0 0 92%;
                width: 92%;
            }
        }

        &_right {
            background-color: $white;
            flex: 0 0 27%;
            width: 27%;
            position: relative;

            @media screen and (max-width: $smartphone-middle) {
                flex: 0 0 8%;
                width: 8%;
            }
        }

        &_more {
            text-align: right;
            position: absolute;
            left: -1.3rem;
            bottom: 0;
            width: 16.5rem;
            z-index: 5;

            .number {
                color: $text;
                font-family: $font-family-base-medium;
                font-size: 3.6rem;
                line-height: .89;
            }

            a {
                color: $blue;
                font-size: 1.6rem;
                line-height: 3.2rem;
                text-decoration: underline;
            }

            @media screen and (max-width: $tablet-small - 67) {
                left: -3.3rem;
            }

            @media screen and (max-width: $smartphone-middle) {
                display: none;
            }
        }

        .carousel-pic {
            border-radius: 1.6rem;
            position: relative;
            height: 27.4rem;
            width: 34rem;
            overflow: hidden;
            transform: skewX(-22deg);

            @media screen and (max-width: $tablet) {
                width: 24.6rem;
                height: 20rem;
            }

            @media screen and (max-width: $tablet-small) {
                width: 18.5rem;
                height: 15rem;
            }

            img {
                width: auto;
                height: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%) skewX(22deg);
            }
        }
    }

    &-expertise {
        padding: 0 2.7rem 8rem;
        font-size: 1.6rem;
        line-height: 1.5;

        @media screen and (max-width: $smartphone-middle) {
            font-size: 1.4rem;
            line-height: 1.28;
            padding: 0 1.5rem 6rem;
        }

        &_top {
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: $tablet) {
                flex-direction: column-reverse;

                & + .btn {
                    display: none;
                }
            }
        }

        h2 {
            margin-bottom: 4.8rem;
        }

        &_content {
            color: rgba($text, 0.7);
            margin: 0 3rem 8.4rem 0;
            max-width: 50rem;

            @media screen and (max-width: $tablet) {
                max-width: none;
            }

            @media screen and (max-width: $tablet-small) {
                line-height: 1.71;
                margin-right: 0;
            }

            .caption {
                color: $text;
                margin-top: 3.2rem
            }
        }

        &_pic {
            width: 100%;
            max-width: 60.7rem;
            margin: -6.6rem 0 1.7rem;

            @media screen and (max-width: $hd - 100) {
                max-width: 50rem;
            }

            @media screen and (max-width: $tablet) {
                margin: 0 auto 3.6rem;
                max-width: none;
            }

            img {
                width: 100%;
            }
        }

        .btn {
            width: 28.3rem;
            position: relative;
            z-index: 2;
        }

        &_list {
            text-align: center;
            padding: 4.3rem 12rem 8rem 4rem;
            position: relative;
            z-index: 1;

            @media screen and (max-width: $tablet) {
                padding: 0;
            }

            &:before {
                background: url(../images/bg-contact.svg) 100% 0 no-repeat;
                content: '';
                display: block;
                width: 124.2rem;
                height: 100%;
                position: absolute;
                top: -3.5rem;
                right: 0;

                @media screen and (max-width: $tablet) {
                    background: rgba($bg, .3);
                    border-radius: 1.6rem;
                    content: '';
                    display: block;
                    width: 194rem;
                    height: calc(100% + 20rem);
                    transform: skew(59deg);
                    position: absolute;
                    top: -48px;
                    right: -38.6rem;
                    z-index: 1;
                }

                @media screen and (max-width: $tablet-small) {
                    right: -53.6rem;
                }
            }

            .list {
                &-item {
                    margin: 0 auto;
                    position: relative;
                    z-index: 2;

                    @media screen and (max-width: $tablet) {
                        margin-bottom: 5.5rem;
                    }
                }

                &-icon {
                    display: block;
                    height: 7.2rem;
                    margin: 0 auto;

                    @media screen and (max-width: $tablet) {
                        height: auto;
                        margin-bottom: 2.5rem;
                    }
                }

                &-first {
                    max-width: 22rem;

                    .list-icon {
                        width: 5rem;

                        img {
                            width: 5rem;
                            height: 5.1rem;
                        }
                    }
                }

                &-second {
                    max-width: 17.2rem;

                    .list-icon {
                        width: 6.6rem;

                        img {
                            width: 6.6rem;
                            height: 4.7rem;
                        }
                    }
                }

                &-third {
                    max-width: 15.6rem;

                    .list-icon {
                        width: 6rem;

                        img {
                            width: 6rem;
                            height: 6rem;
                        }
                    }
                }
            }

            & + .btn {
                display: none;

                @media screen and (max-width: $tablet) {
                    display: block;
                    margin: 0 auto;
                    width: 22.4rem;
                }
            }
        }
    }

    &-contact {
        padding: 0 2.7rem;

        .container {
            padding: 6.9rem 0 6.2rem;
            position: relative;

            @media screen and (max-width: $smartphone-middle) {
                padding-top: 5.4rem;
            }

            &:before {
                background: url(../images/bg-contact.svg) 100% 0 no-repeat;
                background-size: cover;
                content: '';
                display: block;
                width: 124.2rem;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;

                @media screen and (max-width: $tablet) {
                    display: none;
                }
            }
        }

        h2 {
            margin-bottom: 6rem;
            position: relative;
        }

        @media screen and (max-width: $tablet) {
            &:before {
                background: url(../images/bg-contact.svg) 100% 0 no-repeat;
                background-size: cover;
                content: '';
                display: block;
                width: 124.2rem;
                height: 100%;
                position: absolute;
                top: 0;
                right: -14rem;
            }
        }
    }

    &-estimate {
        background-color: $white;
        box-shadow: .2rem .2rem 1rem rgba($black, 0.1);
        border-radius: 1.6rem;
        max-width: 101.3rem;
        margin: 0 auto;
        padding: 5.6rem 9rem 4.2rem;

        h3 {
            font-family: $font-family-base-medium;
            font-size: 2.4rem;
            line-height: 1.08;
            margin-bottom: 5.6rem;
            text-align: center;
        }

        @media screen and (max-width: $hd - 100) {
            padding-left: 2.7rem;
            padding-right: 2.7rem;
        }

        @media screen and (max-width: $smartphone-middle) {
            background-color: transparent;
            box-shadow: none;
            border-radius: 0;
            padding: 0 0 4.2rem;
        }
    }

    &-subscribe {
        overflow: hidden;
        padding: 0 2.7rem;
        position: relative;

        @media screen and (max-width: $smartphone-middle) {
            padding: 0 1.5rem;
        }

        &:before {
            background-color: $bg-input;
            content: '';
            display: block;
            width: 40%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
        }

        .container {
            display: flex;
            padding: 5.4rem 0 6.3rem;
            position: relative;

            &:before {
                background: url(../images/bg-newsletter.svg) 0 0 no-repeat;
                background-size: cover;
                content: '';
                display: block;
                width: 123.9rem;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }

            @media screen and (max-width: $hd + 30) {
                display: block;
            }
        }

        &_left {
            flex: 0 0 34.7rem;
            width: 34.7rem;
            margin: 0 6.3rem 0 14.3rem;
            position: relative;

            @media screen and (max-width: $hd + 30) {
                margin: 0 auto 3rem;
            }

            @media screen and (max-width: $smartphone-small) {
                width: 100%;
            }

            .title {
                font-family: $font-family-base-medium;
                font-size: 2.4rem;
                margin-bottom: 2rem;
                padding-left: 4.5rem;
                position: relative;

                &:before {
                    background: url(../images/icon-email.svg) 0 0 no-repeat;
                    background-size: cover;
                    content: '';
                    display: block;
                    position: absolute;
                    top: .9rem;
                    left: 0;
                    width: 2.8rem;
                    height: 2rem;
                }
            }
        }

        &_form {
            display: flex;
            align-items: flex-end;
            position: relative;

            @media screen and (max-width: $hd + 30) {
                justify-content: center;
            }

            @media screen and (max-width: $tablet-small - 60) {
                display: block;
            }

            .input {
                margin-right: 1.6rem;
                flex: 0 0 38.6rem;
                width: 38.6rem;

                input {
                    background-color: $white;
                }

                @media screen and (max-width: $tablet-small - 60) {
                    margin: 0 0 1.6rem;
                    width: 100%;
                }
            }

            .btn {
                height: 4.8rem;
                padding: .7rem 2rem;
                width: 17rem;

                &:after {
                    display: none;
                }

                @media screen and (max-width: $tablet-small - 60) {
                    margin: 0 auto;
                }
            }
        }
    }

    &-conseil {
        padding: 10rem 2.7rem 12rem;

        @media screen and (max-width: $smartphone-middle) {
            padding: 4rem 1.5rem;
        }

        .btn {
            cursor: pointer;
            width: 20rem;
            margin: 0 auto 6rem;

            @media screen and (max-width: $smartphone-middle) {
                margin-bottom: 4rem;
            }
        }

        &_pic {
            display: flex;
            justify-content: center;

            img {
                width: 100%;
                max-width: 99.5rem;
            }
        }
    }

    &-announce {
        padding: 0 2.7rem 8.8rem;

        @media screen and (max-width: $smartphone-middle) {
            padding: 0 1.5rem 6rem;
        }
    }
}


.announce {
    &-text {
        flex: 2;
        display: flex;
        align-items: center;
        background-color: $text;
        border-radius: 1.6rem;
        color: $white;
        margin-right: .3rem;
        padding: 4rem 0 4rem 5.5rem;
        position: relative;
        z-index: 3;

        @media screen and (max-width: $hd - 98) {
            padding-left: 4rem;
        }

        @media screen and (max-width: $tablet) {
            margin: 0 15.7rem 2rem 0;
        }

        @media screen and (max-width: $tablet-middle - 1) {
            margin-right: 16.7rem;
        }

        @media screen and (max-width: $tablet-small) {
            padding-left: 4rem;
        }

        @media screen and (max-width: $smartphone + 56) {
            margin-right: 18.7rem;
        }

        @media screen and (max-width: $tablet-small) {
            margin-right: 0;
            padding-right: 4rem;
        }

        @media screen and (max-width: $smartphone-small) {
            padding: 2.5rem;
        }

        &:before {
            background-color: $text;
            border-radius: 1.6rem .8rem 1.6rem 1.6rem;
            content: '';
            display: block;
            width: 30rem;
            height: 100%;
            position: absolute;
            top: 0;
            right: -7.4rem;
            transform: skewX(25deg);

            @media screen and (max-width: $l-hd - 54) {
                right: -8.3rem;
            }

            @media screen and (max-width: $hd - 98) {
                right: -9.3rem;
            }

            @media screen and (max-width: $tablet) {
                right: -8.3rem;
            }

            @media screen and (max-width: $smartphone + 56) {
                right: -9.3rem;
            }

            @media screen and (max-width: $tablet-small) {
                display: none;
            }
        }

        .text {
            position: relative;
            z-index: 2;

            @media screen and (max-width: $hd - 98) {
                font-size: 15px;
            }

            @media screen and (max-width: $desktop) {
                font-size: 14px;
            }

            .title {
                font-family: $font-family-base-medium;
                font-size: 2.4rem;
                margin-bottom: 1.4rem;
            }

            .subtitle {
                font-family: $font-family-base-medium;
            }
        }
    }

    &-pic {
        flex: 0 0 40%;
        max-width: 40%;
        border-radius: 1rem 1.6rem 1.6rem 0;
        margin-left: 1.4rem;
        overflow: hidden;
        position: relative;

        &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 32.3rem 0 0 15.5rem;
            border-color: transparent transparent transparent $white;
            position: absolute;
            bottom: 0;

            @media screen and (max-width: $hd - 100) {
                border-width: 35.3rem 0 0 16.7rem;
            }

            @media screen and (max-width: $tablet) {
                border-width: 32.3rem 0 0 16.7rem;
            }
        }

        @media screen and (max-width: $l-hd - 54) {
            margin-left: 2.4rem;
        }

        @media screen and (max-width: $desktop + 168) {
            margin-right: 4rem;
        }

        @media screen and (max-width: $tablet) {
            position: absolute;
            right: 0;
            bottom: 0;
            margin-right: 0;
        }

        @media screen and (max-width: $tablet-small) {
            border-radius: 1.6rem;
            margin-left: 0;
            width: 100%;
            max-width: 100%;
            position: relative;

            &:before {
                display: none;
            }
        }

        img {
            width: auto;
            height: 100%;
            max-height: 33.5rem;

            @media screen and (max-width: $desktop + 168) {
                max-height: 35.8rem;
            }

            @media screen and (max-width: $tablet) {
                max-height: 33.5rem;
            }

            @media screen and (max-width: $tablet-small) {
                width: 100%;
                height: auto;
            }
        }
    }

    &-block {
        display: flex;
        border-radius: 1.6rem;
        overflow: hidden;

        @media screen and (max-width: $tablet) {
            display: block;
            padding-bottom: 33.5rem;
            position: relative;
        }

        @media screen and (max-width: $tablet-small) {
            padding-bottom: 0;
        }
    }
}

.intro-text {
    margin-bottom: 6rem;

    .subtitle {
        font-size: 2.2rem;

        @media screen and (max-width: $smartphone-middle) {
            font-size: 1.8rem;
        }
    }
}

.static {
    &-article {
        margin-bottom: 10rem;
    }

    &-div {
        margin-bottom: 3rem;

        p,
        ul {
            margin-bottom: .7rem;
        }

        .subtitle {
            font-family: $font-family-base-medium;
        }

        a {
            color: $light-blue;

            &:hover {
                color: $text;
                text-decoration: underline;
            }
        }
    }

    &-date {
        text-align: right;
    }
}