input,
button,
textarea,
select {
    background: none;
    border: none;
    border-radius: 0;
    color: $text;
    font-family: $font-family-base-medium;
    font-size: 1.6rem;
    height: 4.8rem;
    width: 100%;
    -webkit-appearance: none;

    &::-webkit-input-placeholder {
        color: $text;
    }
    &::-moz-placeholder {
        color: $text;
    }
    &:-moz-placeholder {
        color: $text;
    }
    &:-ms-input-placeholder {
        color: $text;
    }

    @media screen and (max-width: $smartphone-middle) {
        height: 4rem;
    }
}

input,
textarea,
select {
    background-color: rgba($bg-input, .7);
    border: .1rem solid rgba($border, .7);
    border-radius: .8rem;
    padding: 0 2.4rem;

    &:-internal-autofill-selected {
        background-color: rgba($bg-input, .7) !important;
        border: .1rem solid rgba($border, .7) !important;
        border-radius: .8rem !important;
        color: $text !important;
    }
}

textarea {
    display: block;
    padding: 1rem 2.4rem;
    height: 9.8rem;
    resize: none;
}

label {
    display: block;
    font-size: 1.4rem;
    line-height: 1.14;
    margin-bottom: 1.1rem;
}

.form {
    &-item {
        width: 100%;
        max-width: 37rem;
        padding-bottom: 2.2rem;
        position: relative;

        &_right {
            display: flex;
            justify-content: flex-end;

            @media screen and (max-width: $tablet) {
                display: block;
            }
        }

        &_checkbox {
            border-top: .1rem solid $border;
            padding-top: 1.7rem;

            input {
                display: none;
            }

            label {
                color: rgba($black, .7);
                cursor: pointer;
                font-size: 1.2rem;
                line-height: 1.5;
                margin-bottom: 0;
                padding: 0 0 2.4rem 3.5rem;
                position: relative;

                &:before {
                    background-color: $white;
                    border: .1rem solid rgba($text, .7);
                    border-radius: .4rem;
                    box-sizing: border-box;
                    content: '';
                    display: block;
                    width: 2rem;
                    height: 2rem;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            input:checked + label {
                &:before {
                    background: $green url(../images/icon-check.svg) 50% 50% no-repeat;
                    background-size: .9rem .8rem;
                }
            }
        }

        &_phone {
            label {
                padding-top: 1.5rem;
            }
        }

        &.error {
            .error-text {
                display: inline-block;
            }

            input,
            textarea {
                border-color: $red;
            }
        }
    }

    &-subtitle {
        border-bottom: .1rem solid $border;
        color: $blue;
        font-family: $font-family-base-medium;
        font-size: 1.6rem;
        line-height: 1.13;
        margin-bottom: 2.4rem;
        padding: 1.6rem 0 1.1rem;
    }
}

.error {
    &-text {
        color: $red;
        display: none;
        font-family: $font-family-base-medium;
    }
}
