.project {
    &-pics {
        flex: 0 0 60.7rem;
        max-width: 60.7rem;
        display: flex;
        justify-content: flex-end;
        padding-right: 6.5rem;
        position: relative;

        @media screen and (max-width: $hd - 120) {
            margin: 0 auto;
            padding: 0 6.5rem;
        }

        @media screen and (max-width: $tablet-small) {
            padding: 0 3.5rem;
        }

        @media screen and (max-width: $smartphone-middle) {
            display: block;
        }

        .pic-big {
            border-radius: 2.2rem 1.1rem 2.7rem .8rem;
            box-shadow: 0 .4rem .4rem 0 rgba($black, .25);
            width: 41.2rem;
            height: 33.1rem;
            overflow: hidden;
            transform: skewX(22deg);
            position: relative;
            z-index: 4;

            @media screen and (max-width: $tablet-small) {
                width: 38rem;
                height: 30.5rem;
            }

            @media screen and (max-width: $smartphone) {
                width: 33.5rem;
                height: 26.9rem;
            }

            @media screen and (max-width: $smartphone-middle) {
                width: 100%;
                max-width: 29.5rem;
                height: 24.2rem;
                margin: 0 auto;
            }

            @media screen and (max-width: $smartphone-small) {
                max-width: 24.5rem;
                height: 19.2rem;
            }

            img {
                width: auto;
                height: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%) skewX(-22deg);
                object-fit: unset;
            }
        }

        .pic-small {
            border-radius: 1rem 1rem .8rem 1.9rem;
            height: 12.6rem;
            width: 15.8rem;
            overflow: hidden;
            transform: skewX(-22deg);
            position: absolute;
            bottom: .7rem;
            left: 2.5rem;

            @media screen and (max-width: $smartphone-middle) {
                display: none;
            }

            img {
                width: auto;
                height: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%) skewX(22deg);
                object-fit: unset;
            }
        }
    }

    &-data {
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: $smartphone-small) {
            display: block;
        }

        li {
            flex: 0 0 50%;
            max-width: 50%;
            padding-top: 2rem;

            @media screen and (max-width: $smartphone-small){
                max-width: none;
            }
        }

        .data-icon {
            display: flex;
            font-family: $font-family-base-bold;
            font-size: 2.4rem;
            margin-bottom: 1rem;

            @media screen and (max-width: $smartphone-middle) {
                font-size: 2.2rem;
            }

            .icon {
                flex: 0 0 3.2rem;
                width: 3.2rem;
                height: 3.2rem;
                display: flex;
                align-items: center;
                margin-right: .9rem;

                img {
                    width: auto;
                    height: 100%;
                }
            }
        }
    }

    &-info {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 2rem;
        position: relative;

        &:first-child {
            &:after {
                background-color: #eaeaea;
                content: '';
                display: block;
                width: .1rem;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;

                @media screen and (max-width: $tablet) {
                    width: 100%;
                    height: .1rem;
                    top: auto;
                    bottom: 0;
                }
            }
        }

        @media screen and (max-width: $hd - 120) {
            margin-bottom: 3rem;
        }

        @media screen and (max-width: $tablet) {
            margin-bottom: 1rem;
            max-width: none;
            padding: 2rem 0;
        }

        h4 {
            font-size: 2.8rem;
            line-height: 1;
            margin-bottom: 2.3rem;
            text-transform: capitalize;

            @media screen and (max-width: $smartphone-middle) {
                font-size: 2.2rem;
            }
        }

        .price {
            background-color: $text;
            background: -webkit-linear-gradient(45deg, $blue, $text);
            background-size: 100%;
            -webkit-background-clip: text;
            -moz-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-text-fill-color: transparent;
            font-family: $font-family-base-bold;
            font-size: 2rem;
            margin-bottom: 3rem;

            @media screen and (max-width: $smartphone-middle) {
                font-size: 1.8rem;
                margin-bottom: 3rem;
            }

            @media screen and (max-width: $smartphone-small) {
                margin-bottom: 1rem;
            }
        }
    }

    &-one {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: .1rem solid $text;
        padding: 5.9rem 0 5.4rem;

        @media screen and (max-width: $tablet) {
            display: block;
            padding: 2rem 0 0;
        }
    }

    &-buttons {
        display: flex;
        justify-content: center;
        margin: 5.9rem 0 7.2rem;

        @media screen and (max-width: $smartphone-middle) {
            display: block;
        }

        .btn {
            flex: 0 0 24rem;
            max-width: 24rem;
            font-size: 1.6rem;
            height: 6rem;
            line-height: 6rem;
            margin: 0 1.6rem;

            @media screen and (max-width: $smartphone-middle) {
                margin: 1.6rem auto 0;
            }
        }
    }

    &-more {
        padding-bottom: 4.5rem;

        h4 {
            font-family: $font-family-base-medium;
            font-size: 3.6rem;
            line-height: .95;
            margin-bottom: 6.5rem;
            text-align: center;

            @media screen and (max-width: $smartphone-middle) {
                font-size: 2.4rem;
            }
        }

        p, ul {
            margin-bottom: 2.5rem;
        }
    }

    &-gallery {
        h3 {
            font-family: $font-family-base-medium;
            font-size: 3.6rem;
            line-height: .95;
            margin-bottom: -2rem;
            text-align: center;

            @media screen and (max-width: $tablet) {
                margin-bottom: 0;
            }

            @media screen and (max-width: $smartphone-middle) {
                font-size: 2.4rem;
            }
        }
    }

    &-main {
        padding: 0 2.7rem;

        @media screen and (max-width: $smartphone-middle) {
            padding: 0 1.5rem;
        }

        h1 {
            margin-bottom: 4rem;
        }

        .intro-text {
            color: rgba($text, .7);

            @media screen and (max-width: $tablet-small) {
                & + .project-one {
                    padding-top: 0;
                }
            }

            p {
                margin-top: 2rem;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}