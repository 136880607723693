.index {
    &-main {
        background: url(../images/bg-welcome.jpg) 50% 50% no-repeat;
        background-size: cover;
        color: $white;
        height: calc(100vh - 4.8rem);
        min-height: 60rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 0 2.7rem;
        position: relative;

        @media screen and (max-width: $smartphone-middle) {
            height: calc(100vh - 1.5rem);
            min-height: 40rem;
            padding: 0 1.5rem;
        }

        &:before {
            background: url(../images/bg-header.png) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 15.5rem;
            height: 13.2rem;
            position: absolute;
            bottom: 0;
            left: 0;

            @media screen and (max-width: $smartphone-middle) {
                width: 8rem;
                height: 6.8rem;
            }
        }

        &_welcome {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.7rem;
            position: relative;
            text-align: center;

            h2 {
                font-size: 4rem;
                margin-bottom: 0;

                @media screen and (max-width: $smartphone-middle) {
                    font-size: 2.4rem;
                    line-height: 1.11;
                    margin-bottom: 1.6rem;
                }
            }

            h1 {
                font-size: 6rem;
                margin-bottom: 0;
                text-shadow: 0 .4rem 0 $text;
                text-transform: none;

                @media screen and (max-width: $tablet-small) {
                    font-size: 4rem;
                }
            }
        }

        p {
            max-width: 48.1rem;
            margin: 0 auto 3.4rem;

            @media screen and (max-width: $smartphone-middle) {
                font-size: 1.4rem;
                line-height: 1.08;
                margin-bottom: 2.7rem;
            }
        }

        .btn {
            margin: 0 auto;
            width: 31.6rem;

            @media screen and (max-width: $smartphone-middle) {
                width: 22.5rem;
            }
        }
    }
}

.text {
    &-center {
        text-align: center;
    }
}