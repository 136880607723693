.iziModal {
    background: none !important;
    box-shadow: none !important;
    max-width: none !important;
    height: 100% !important;

    .iziModal {
        &-wrap {
            background: url(../images/bg-blue.svg) 50% 0 no-repeat;
            height: 100% !important;
          
            @media screen and (max-width: $hd) {
                background-position: 70% 0;
            }

            @media screen and (max-width: $tablet) {
                background: $blue;
                background: -moz-linear-gradient(left, $blue 0%, $text 100%);
                background: -webkit-linear-gradient(left, $blue 0%, $text 100%);
                background: linear-gradient(to right, $blue 0%, $text 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#247ba0', endColorstr='#0a2239',GradientType=1 );
            }
        }

        &-content {
            display: flex;
            justify-content: flex-end;
            height: 100%;
            position: relative;

            @media screen and (max-width: $tablet) {
                justify-content: center;
            }
        }
    }
}

.modal {
    &-body {
        color: $white;
        padding: 16.8rem 23.5rem 5rem 0;

        @media screen and (max-width: $hd) {
            padding-right: 13.5rem;
        }

        @media screen and (max-width: $desktop + 80) {
            padding-right: 6rem;
        }

        @media screen and (max-width: $tablet) {
            width: 37rem;
            padding-right: 0;
        }

        @media screen and (max-width: $smartphone-middle) {
            padding-top: 10.8rem;
        }

        @media screen and (max-width: $smartphone-small) {
            padding: 10.8rem 2rem 4.8rem;
            width: 100%;
        }
    }

    &-close {
        background: none;
        border: none;
        border-radius: 0;
        width: 8.8rem;
        height: 5.8rem;
        position: absolute;
        top: 7.7rem;
        right: 13.4rem;

        @media screen and (max-width: $desktop + 80) {
            right: 6rem;
        }

        &:before {
            background: url(../images/modal-close.svg) 100% 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 8.8rem;
            height: 5.8rem;
            opacity: 1;
            position: absolute;
            top: 0;
            right: 0;
            transition: all .1s linear;
        }

        &:after {
            background-image: url(../images/modal-close_h.svg);
            background-size: cover;
            content: '';
            display: block;
            width: 7.4rem;
            height: 5.8rem;
            opacity: 0;
            position: absolute;
            top: 0;
            right: 0;
            transition: all .1s linear;
        }

        &:hover {
            &:before {
                opacity: 0;
            }

            &:after {
                opacity: 1;
            }
        }

        &:active {
            &:after {
                background-image: url(../images/modal-close_a.svg);
            }
        }

        @media screen and (max-width: $tablet) {
            width: 6.8rem;
            height: 4.5rem;
            top: 8.5rem;
            right: 12.9rem;

            &:before{
                width: 6.8rem;
                height: 4.5rem;
            }

            &:after {
                width: 5.75rem;
                height: 4.5rem;
            }

            &:active,
            &:hover{
                &:after {
                    width: 5.75rem;
                    height: 4.5rem;
                }
            }

            &:hover {
                &:before{
                    width: 6.8rem;
                    height: 4.5rem;
                }
            }
        }

        @media screen and (max-width: $smartphone) {
            top: 5.2rem;
            right: 10.5rem;
        }

        @media screen and (max-width: $smartphone-middle) {
            background-image: url(../images/modal-close_sm.svg);
            width: 3.3rem;
            height: 3.3rem;
            top: 2.9rem;
            right: 2rem;

            &:before,
            &:after {
                background-image: url(../images/modal-close_sm.svg);
                width: 3.3rem;
                height: 3.3rem;
            }

            &:hover {
                &:before,
                &:after {
                    width: 3.3rem;
                    height: 3.3rem;
                }
            }

            &:active {
                &:after {
                    background-image: url(../images/modal-close_sm-a.svg);
                    width: 3.3rem;
                    height: 3.3rem;
                }
            }
        }
    }

    &-title {
        font-family: $font-family-base-medium;
        font-size: 2.4rem;
        line-height: 1.08;
        margin-bottom: 5rem;
        text-align: center;

        @media screen and (max-width: $smartphone-middle) {
            font-size: 1.8rem;
            margin-bottom: 2.4rem;
        }
    }

    &-pic {
        flex: 0 0 44.1rem;
        width: 44.1rem;
        border-radius: 1.5rem;
        box-shadow: 0 .4rem 4.4rem 0 rgba($white, .21);
        margin: 10.3rem 3.7rem 0 0;
        overflow: hidden;
        position: relative;

        @media screen and (max-width: $tablet) {
            display: none;
        }

        img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
        }
    }

    &-form {
        flex: 0 0 37rem;
        max-width: 37rem;
        margin-left: 3.7rem;

        @media screen and (max-width: $tablet) {
            margin-left: 0;
        }
    }

    &-content {
        display: flex;
        padding-bottom: 5rem;

        @media screen and (max-width: $tablet) {
            display: block;
        }

        .form-item {
            input,
            textarea {
                background-color: rgba($white,.7);
                border-color: transparent;
            }

            &_checkbox {
                border-top: none;
                padding-top: 0;

                label {
                    color: $white;
                    max-width: 22.5rem;

                    &:before {
                        background-color: transparent;
                        border-color: $white;
                        top: .8rem;
                    }
                }

                a {
                    color: $white;
                    text-decoration: underline;
                }

                @media screen and (max-width: $smartphone-small) {
                    max-width: 37rem;
                    margin: 0 auto;
                }
            }

            &.error {
                .error-text {
                    display: inline-block;
                }

                input,
                textarea {
                    border-color: $red;
                }
            }

            @media screen and (max-width: $tablet) {
                margin: 0 auto;
            }
        }

        @media screen and (max-width: $smartphone-small) {
            .btn {
                max-width: 37rem;
                margin: 0 auto;
            }
        }
    }
}