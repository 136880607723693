.owl {
    &-carousel {
        .owl {
            &-stage-outer {
                overflow: visible;
            }

            &-nav {
                button.owl {
                    &-prev,
                    &-next {
                        border-radius: .8rem;
                        width: 6.8rem;
                        height: 5.5rem;
                        position: relative;
                        transform: skewX(-22deg);

                        @media screen and (max-width: $smartphone-middle) {
                            width: 4.4rem;
                            height: 3.5rem;
                        }

                        span {
                            display: none;
                        }
                    }

                    &-prev {
                        &:before {
                            background: url(../images/arrow_b.svg) 0 0 no-repeat;
                            background-size: cover;
                            content: '';
                            display: block;
                            width: 1.9rem;
                            height: 1.2rem;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%) skewX(22deg);

                            @media screen and (max-width: $smartphone-middle) {
                              width: 1.25rem;
                              height: .76rem;
                            }
                        }
                    }

                    &-next {
                        background: $light-green;
                        background: -moz-linear-gradient(left, $green 0%, $light-green 100%);
                        background: -webkit-linear-gradient(left, $green 0%, $light-green 100%);
                        background: linear-gradient(to right, $green 0%, $light-green 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34ad73', endColorstr='#84d2b1',GradientType=1 );
                        transition: all .2s linear;

                        &:before {
                            background: url(../images/arrow_w.svg) 0 0 no-repeat;
                            background-size: cover;
                            content: '';
                            display: block;
                            width: 2rem;
                            height: 1.2rem;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%) skewX(22deg);

                            @media screen and (max-width: $smartphone-middle) {
                                width: 1.25rem;
                                height: .76rem;
                            }
                        }

                        &:hover {
                            background: $green;
                        }
                    }
                }
            }
        }
    }
}