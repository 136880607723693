.small-modal {
    .iziModal-wrap {
        background: none;

        .iziModal-content {
            justify-content: center;
            align-items: center;
        }

        .modal {
            &-content {
                display: block;
                padding-bottom: 0;
            }

            &-close {
                background: url(../images/icon-close.svg) 0 0 no-repeat;
                background-size: cover;
                display: block;
                width: 1.8rem;
                height: 1.8rem;
                position: absolute;
                top: 4.3rem;
                right: 3rem;
                z-index: 10;

                @media screen and (max-width: $smartphone-middle) {
                    top: 4rem;
                }

                &:before,
                &:after {
                    display: none;
                }
            }

            &-body {
                background: url(../images/bg-modal.png) 50% 0 no-repeat;
                border-radius: 1.6rem;
                width: 63rem;
                overflow: hidden;
                padding: 3.8rem 3rem 3.4rem;
                position: relative;

                .title {
                    color: $white;
                    font-family: $font-family-base-medium;
                    font-size: 2.4rem;
                    line-height: 1.083;
                    margin-bottom: 2.4rem;
                    padding: 0 4.5rem;
                    position: relative;

                    @media screen and (max-width: $smartphone-middle) {
                        font-size: 2rem;
                    }

                    &:before {
                        background: url(../images/icon-email_w.svg) 0 0 no-repeat;
                        background-size: cover;
                        content: '';
                        display: block;
                        position: absolute;
                        top: .3rem;
                        left: 0;
                        width: 2.8rem;
                        height: 2rem;

                        @media screen and (max-width: $smartphone-middle) {
                            top: .1rem;
                        }
                    }
                }

                .section-subscribe {
                    &_modal {
                        color: rgba($white, .8);
                        font-size: 1.8rem;
                        margin-bottom: 2.4rem;
                        max-width: 44rem;

                        @media screen and (max-width: $smartphone-middle) {
                            font-size: 1.6rem;
                        }
                    }

                    &_form .input {
                        label {
                            color: rgba($white, .8);
                        }

                        input {
                            background-color: rgba($white, .7);
                            border: none;
                        }
                    }

                    &_thankyou {
                        display: flex;
                        justify-content: space-between;

                        @media screen and (max-width: $smartphone-small) {
                            display: block;
                        }

                        .text {
                            width: 31rem;
                            margin-right: 1rem;

                            @media screen and (max-width: $smartphone-small) {
                                margin: 0 0 2rem;
                                width: 100%;
                            }
                        }

                        .btn {
                            height: 4.8rem;
                            padding: .7rem 2rem;
                            width: 17rem;
                        }
                    }
                }
            }


        }
    }
}

#newsletter {
    &-thankyou {
        .modal {
            &-body {
                .title {
                    margin-bottom: 4rem;
                }

                .section-subscribe_modal {
                    margin-bottom: 0;
                    max-width: none;
                }
            }
        }
    }
}

.modal {
    &-user {
        display: flex;
        align-items: center;
        color: $white;
        max-width: 114rem;

        @media screen and (max-width: $tablet-small) {
            display: block;
        }

        &_pic {
            flex: 0 0 37rem;
            width: 37rem;
            height: 41.9rem;
            background-color: $white;
            box-shadow: 0 .4rem 2rem rgba($light-blue, .4);
            border-radius: 1.6rem;
            margin-right: 5rem;
            overflow: hidden;

            img {
                width: 100%;
            }

            @media screen and (max-width: $tablet-middle) {
                flex: 0 0 30rem;
                width: 30rem;
                height: 34rem;
            }

            @media screen and (max-width: $tablet-small) {
                margin-bottom: 2rem;
            }

            @media screen and (max-width: $smartphone-middle) {
                width: 100%;
                max-width: 30rem;
                height: auto;
            }
        }

        &_name {
            font-family: $font-family-base-medium;
            font-size: 3.6rem;
            line-height: 1.08;
            margin-bottom: 1rem;
        }

        &_job {
            color: $light-green;
            font-family: $font-family-base-medium;
            font-size: 2rem;
            margin-bottom: 1.5rem;
        }

        &_desc {
            margin-top: 3rem;
        }

        &_lang {
            margin-top: 3rem;

            p {
                color: $light-blue;
                font-size: 1.2rem;
                text-transform: uppercase;
            }
        }

        &_content {
            flex: 2;

            a {
                color: $light-blue;
                text-decoration: none;
            }

            @media screen and (max-width: $tablet) {
                font-size: 1.4rem;
            }
        }
    }

    &-body_user {
        @media screen and (max-width: $l-hd + 40) {
            padding-left: 4rem;
            padding-right: 13.4rem;
        }

        @media screen and (max-width: $desktop + 80) {
            padding-right: 6rem;
        }

        @media screen and (max-width: $tablet) {
            width: 100%;

            .modal-close {
                right: 6rem;
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            padding-left: 2rem;
            padding-right: 2rem;

            .modal-close {
                right: 2rem;
            }
        }
    }
}