.blog {
    &-main {
        margin-bottom: 5.2rem;
        padding: 0 2.7rem;

        @media screen and (max-width: $smartphone-middle) {
            padding: 0 1.5rem;
        }

        .subtitle {
            font-size: 1.8rem;
            line-height: 1.39;
            margin: -4.5rem 0 5.6rem;
            max-width: 76rem;

            @media screen and (max-width: $smartphone-middle) {
                font-size: 1.6rem;
                margin: 0 0 3rem;
            }
        }
    }

    &-pic {
        border-radius: 1.6rem 0 0 1.6rem;
        flex: 0 0 41.2rem;
        width: 41.2rem;
        overflow: hidden;

        img {
            width: 100%;
        }

        &1 {
            background-color: #e8e9ec;
        }

        &2 {
            background-color: #ecf0f5;
        }

        &5 {
            background-color: #f5f5f5;
        }

        @media screen and (max-width: $tablet) {
            border-radius: 0;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 39rem;

            img {
                width: auto;
                height: 100%;
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            height: 28rem;
        }
    }

    &-content {
        flex-grow: 2;
        border-radius: 0 1.6rem 1.6rem 0;
        color: rgba($text, .7);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 6rem 10.6rem 5.6rem 6.2rem;
        position: relative;

        @media screen and (max-width: $hd - 100) {
            padding: 2rem 5rem;
        }

        @media screen and (max-width: $tablet) {
            padding: 5rem;
            position: static;
        }

        @media screen and (max-width: $tablet-small) {
            padding: 5rem 2.5rem;
        }

        @media screen and (max-width: $smartphone-middle) {
            padding: 2rem 1.5rem;
        }

        .corner {
            background: url(../images/bg-about.png) 0 0 no-repeat;
            background-size: cover;
            width: 16.5rem;
            height: 14.5rem;
            position: absolute;
            top: -1.7rem;
            right: -1.7rem;

            @media screen and (max-width: $smartphone-middle) {
                width: 8rem;
                height: 7rem;
                top: -.8rem;
                right: -.8rem;
            }

            img {
                position: absolute;
                top: 4.3rem;
                right: 4.7rem;
                width: 2rem;
                height: 1.2rem;

                @media screen and (max-width: $smartphone-middle) {
                    top: 2.2rem;
                    right: 2.2rem;
                    width: 1.2rem;
                    height: .7rem;
                }
            }
        }

        &_text {
            margin-bottom: 2rem;
        }

        h4 {
            font-family: $font-family-base-medium;
            font-size: 2.4rem;
            line-height: 1.083;
            margin-bottom: 2.5rem;
            text-transform: uppercase;

            @media screen and (max-width: $smartphone-middle) {
                font-size: 2rem;
            }
        }
    }

    &-one {
        background-color: $white;
        box-shadow: .2rem .2rem 1rem rgba($black, .1);
        border-radius: 1.6rem;
        color: rgba($text, .7);
        display: flex;
        margin-bottom: 2.4rem;

        @media screen and (max-width: $tablet) {
            display: block;
            position: relative;
        }

        &.first {
            .about-content {
                .corner {
                    background-image: url(../images/bg-about2.png);
                }
            }
        }
    }

    &-date {
        color: $grey;
        font-size: 1.8rem;
        margin-bottom: 1.7rem;
    }

    &-big {
        position: relative;
        z-index: 4;

        h1 {
            max-width: 42rem;
            margin-bottom: 3.4rem;
            text-transform: uppercase;

            + p {
                max-width: 38rem;
                padding-left: 5.4rem;
            }
        }

        h3 {
            font-family: $font-family-base-medium;
            font-size: 2.4rem;
            line-height: 1.083;
            margin-bottom: 3.5rem;

            @media screen and (max-width: $smartphone-middle) {
                font-size: 2.2rem;
            }
        }

        a {
            color: $blue;
        }

        p {
            margin-bottom: 2.5rem;
        }

        .main {
            font-size: 1.8rem;
            line-height: 1.56;
            margin-bottom: 11.8rem;

            @media screen and (max-width: $tablet-middle) {
                margin-bottom: 3rem;
            }

            @media screen and (max-width: $smartphone-middle) {
                font-size: 1.6rem;
            }
        }

        .highlighted {
            font-family: $font-family-base-medium;
            font-size: 1.8rem;
            line-height: 1.11;
            margin-bottom: 2.5rem;
        }

        &_pic {
            background-color: $bg-input;
            border-radius: 2rem;
            margin: 8.7rem 0;
            overflow: hidden;
            padding-bottom: 5rem;

            .pic-big {
                margin-bottom: 5.5rem;

                img {
                    width: 100%;
                }
            }

            @media screen and (max-width: $hd) {
                .blog-columns {
                    &_left,
                    &_top,
                    &_right {
                        padding: 0 3rem;
                    }
                }
            }

            @media screen and (max-width: $tablet-middle) {
                .blog-columns {
                    &_left {
                        margin-bottom: 3rem;
                    }
                }
            }

            @media screen and (max-width: $smartphone-middle) {
                .blog-columns {
                    &_left,
                    &_top,
                    &_right {
                        padding: 0 1.5rem;
                    }
                }
            }
        }

        &_top {
            padding-bottom: 3.6rem;
            position: relative;

            &:before {
                background-color: $bg-input;
                content: '';
                display: block;
                width: 100rem;
                height: 32.6rem;
                position: absolute;
                bottom: 0;
                left: -45%;

                @media screen and (max-width: $desktop) {
                    display: none;
                }
            }

            &:after {
                background: url(../images/bg-contact.svg) 0 0 no-repeat;
                content: '';
                display: block;
                width: 124.2rem;
                height: 32.6rem;
                position: absolute;
                right: 2.4rem;
                bottom: 0;
            }
        }

        &_first {
            margin-bottom: 23rem;

            @media screen and (max-width: $tablet-middle) {
                margin-bottom: 5rem;
            }

            .pic {
                padding-top: 2rem;

                @media screen and (max-width: $tablet-middle) {
                    padding-top: 0;
                }
            }

            .blog-columns2 {
                &_left {
                    padding-left: 4.6rem;

                    @media screen and (max-width: $hd) {
                        padding-left: 0;
                    }
                }
            }

            & + .blog-columns2 {
                .blog-columns2_left {
                    margin-top: -31rem;

                    @media screen and (max-width: $tablet-middle) {
                        margin: 0 0 3rem;
                        padding: 0;
                    }

                    .font-bold {
                        max-width: 35.5rem;
                    }
                }
            }
        }
    }

    &-columns {
        display: flex;

        @media screen and (max-width: $tablet-middle) {
            display: block;
        }

        &_left {
            flex: 50%;
            width: 50%;
            padding: 0 6.7rem 0 10rem;

            @media screen and (max-width: $hd) {
                padding: 0 2rem 0 0;
            }

            @media screen and (max-width: $tablet-middle) {
                padding: 0 0 2rem;
                width: 100%;
            }
        }

        &_right {
            flex: 50%;
            width: 50%;
            padding: 0 11.5rem 0 2rem;

            @media screen and (max-width: $hd) {
                padding-right: 0;
            }

            @media screen and (max-width: $tablet-middle) {
                padding: 0;
                width: 100%;
            }
        }

        &_top {
            padding-left: 10rem;
            margin-bottom: 3.7rem;

            @media screen and (max-width: $hd) {
                padding-left: 0;
            }

            h3 {
                margin-bottom: 2rem;
            }

            p {
                max-width: 43.3rem;
            }
        }
    }

    &-columns2 {
        display: flex;

        @media screen and (max-width: $tablet-middle) {
            display: block;
        }

        &_left {
            flex: 48.16%;
            width: 48.16%;
            padding: 0 2.5rem 0 10rem;

            @media screen and (max-width: $hd) {
                padding-left: 0;
            }

            @media screen and (max-width: $tablet-middle) {
                width: 100%;
            }

            .highlighted {
                margin-top: 3.5rem;
            }
        }

        &_right {
            flex: 51.84%;
            width: 51.84%;

            @media screen and (max-width: $tablet-middle) {
                width: 100%;
            }

            .pic {
                img {
                    width: 100%;
                }
            }
        }

        &.blog9 {
            .main {
                max-width: 45%;

                @media screen and (max-width: $tablet-middle) {
                    max-width: none;
                }
            }
        }
    }

    &-text {
        padding: 0 8.7rem 0 10rem;

        .highlighted {
            margin-top: 3.5rem;
        }
    }
}

.link-more {
    color: $blue;
    text-decoration: underline;
}