.header {
    background-color: $white;
    border-bottom: .1rem solid $text;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 12rem;
    z-index: 8;

    @media screen and (max-width: $smartphone-middle) {
        height: 6.2rem;
    }

    &.scroll {
        position: fixed;
        width: calc(100% - 12.8rem);
        left: 6.4rem;

        @media screen and (max-width: $hd) {
            width: calc(100% - 8.8rem);
            left: 4.4rem;
        }

        @media screen and (max-width: $smartphone) {
            width: calc(100% - 4rem);
            left: 2rem;
        }
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        max-width: 140rem;
        padding: 0 22rem 0 28rem;
        position: relative;

        @media screen and (max-width: $l-hd) {
            padding-left: 21rem;
            padding-right: 18rem;
        }

        @media screen and (max-width: $desktop + 100) {
            justify-content: flex-end;
            padding-left: 28rem;
            padding-right: 2.7rem;
        }

        @media screen and (max-width: $smartphone-middle) {
            padding: 0 1rem 0 1.7rem;
        }
    }

    &-logo {
        display: block;
        width: 26.9rem;
        position: absolute;
        top: 3.7rem;
        left: 0;

        img {
            width: 100%;
        }

        @media screen and (max-width: $xl-hd) {
            width: 25rem;
            left: 1.5rem;
        }

        @media screen and (max-width: $l-hd) {
            width: 18rem;
            top: 4.2rem;
        }

        @media screen and (max-width: $desktop + 100) {
            width: 25rem;
            top: 3.7rem;
        }

        @media screen and (max-width: $smartphone-middle) {
            top: 1.9rem;
            left: 1.7rem;
            width: 15.3rem;
            height: 2.8rem;
        }
    }

    &-nav {
        height: 100%;
    }

    &-menu {
        display: flex;
        font-size: 1.7rem;
        line-height: 1.06;
        height: 100%;
        text-transform: uppercase;
        position: relative;

        @media screen and (max-width: $l-hd + 10) {
            font-size: 1.6rem;
        }

        @media screen and (max-width: $hd - 60) {
            font-size: 1.4rem;
        }

        @media screen and (max-width: $desktop + 100) {
            font-size: 1.6rem;
        }

        li {
            display: flex;
            align-items: center;
            padding: 0 1.2rem;

            &.active {
                font-family: $font-family-base-medium;
                position: relative;

                &:before {
                    background: url(../images/bg-menu.png) 0 0 no-repeat;
                    content: '';
                    display: block;
                    width: 3.2rem;
                    height: 1.4rem;
                    position: absolute;
                    bottom: -.1rem;
                    left: 50%;
                    transform: translateX(-50%)
                }
            }

            span {
                cursor: pointer;
            }

            @media screen and (max-width: $xl-hd) {
                padding: 0 1rem;
            }
        }

        a {
            color: $text;
        }
    }

    .btn {
        width: 18rem;
        text-transform: uppercase;
        position: absolute;
        top: 3rem;
        right: 0;

        @media screen and (max-width: $xl-hd) {
            right: 1.5rem;
        }

        @media screen and (max-width: $l-hd) {
            width: 14rem;

            &-left:after {
                left: 1rem;
            }
        }

        @media screen and (max-width: $desktop + 100) {
            width: 18rem;

            &-left:after {
                left: 2rem;
            }
        }

        &-menu {
            background-color: $green2;
            border-radius: .8rem;
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 4.5rem;
            height: 4.5rem;
            margin-left: 2rem;
            transition: all .2s linear;

            i {
                background-color: $white;
                display: block;
                width: 2.1rem;
                height: .2rem;
                margin: .2rem 0;
            }

            &:hover {
                background-color: $green;
            }

            @media screen and (max-width: $desktop + 100) {
                display: flex;
            }

            @media screen and (max-width: $smartphone-middle) {
                width: 3.3rem;
                height: 3.3rem;
                margin-left: .4rem;

                i {
                    width: 1.3rem;
                    height: .1rem;
                }
            }
        }
    }
}

.mobile {
    &-logo {
        display: none;

        @media screen and (max-width: $desktop + 100) {
            display: block;
            width: 25rem;
            margin-bottom: 8rem;

            img {
                width: 100%;
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            width: 20rem;
            margin-bottom: 5rem;
        }
    }

    &-menu {
        height: 100%;

        @media screen and (max-width: $desktop + 100) {
            position: fixed;
            top: 0;
            left: 100%;
            width: 100%;
            z-index: 10;
            background: $light-green;
            background: -moz-linear-gradient(left, $light-green 0%, $green 100%);
            background: -webkit-linear-gradient(left, $light-green 0%, $green 100%);
            background: linear-gradient(to right, $light-green 0%, $green 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#84d2b1', endColorstr='#34ad73',GradientType=1 );
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s,visibility .3s cubic-bezier(.785,.135,.15,.86);

            .header {
                &-nav {
                    height: auto;
                }

                &-menu {
                    flex-direction: column;
                    align-items: center;

                    li {
                        margin-bottom: 4rem;

                        &.active {
                            &:before {
                                background: none;
                            }
                        }
                    }
                }
            }

            .btn {
                position: relative;
                right: auto;
                top: auto;

                &-left {
                    width: 18.4rem;

                    &:before {
                        display: none;
                    }
                }
            }
        }

        &_close {
            display: none;

            @media screen and (max-width: $desktop + 100) {
                background: url(../images/menu-close.svg) 0 0 no-repeat;
                background-size: cover;
                display: block;
                position: absolute;
                top: 8.4rem;
                right: 7.1rem;
                width: 4.5rem;
                height: 4.5rem;
            }

            @media screen and (max-width: $smartphone) {
                top: 5.2rem;
                right: 4.7rem;
            }

            @media screen and (max-width: $smartphone-middle) {
                top: 2.9rem;
                right: 2.9rem;
                width: 3.3rem;
                height: 3.3rem;
            }
        }
    }

    &-contact {
        background-color: $text;
        border-radius: .8rem;
        display: none;
        justify-content: center;
        align-items: center;
        width: 5.9rem;
        height: 4.5rem;
        position: relative;

        &:before {
            background-color: $text;
            border-radius: .8rem;
            content: '';
            display: block;
            width: 2.4rem;
            height: 100%;
            transform: skew(-17deg);
            position: absolute;
            top: 0;
            left: -.5rem;
            transition: all .2s linear;
        }

        &:hover {
            &:before {
                left: 0;
                transform: skew(0deg);
            }
        }

        &:active {
            background-color: $blue;

            &:before {
                background-color: $blue;
            }
        }

        img {
            width: 2.3rem;
            height: 2.3rem;
            position: relative;
        }

        @media screen and (max-width: $desktop + 100) {
            display: flex;
        }

        @media screen and (max-width: $smartphone-middle) {
            width: 4.3rem;
            height: 3.3rem;

            &:before {
                transform: skew(-13deg);
                left: -3px;
            }

            img {
                width: 1.7rem;
                height: 1.7rem;
            }
        }
    }
}

.menu-open {
    overflow-y: hidden;
    touch-action: none;

    .mobile {
        &-menu {
            left: 0;
            opacity: 1;
            visibility: visible;
        }
    }
}