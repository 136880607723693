.footer {
    background-color: $text;
    color: $white;
    font-size: 1.6rem;
    line-height: 1.5;
    margin-top: 1.6rem;
    padding: 3.4rem 0 4rem;
    position: relative;
    z-index: 5;

    &:before {
        background-color: $text;
        content: '';
        display: block;
        width: 87%;
        height: 1.6rem;
        position: absolute;
        top: -1.6rem;
        left: 0;

        @media screen and (max-width: $hd) {
            width: 81%;
        }

        @media screen and (max-width: $tablet) {
            width: 68%;
        }

        @media screen and (max-width: $smartphone-small) {
            height: .8rem;
            top: -.8rem;
        }
    }

    .container {
        display: flex;
        justify-content: space-between;
        position: relative;

        @media screen and (max-width: $xl-hd) {
            max-width: 107rem;
        }

        @media screen and (max-width: $l-hd + 40) {
            margin: 0;
            max-width: none;
        }
    }

    &-logo {
        display: block;
        width: 21.8rem;
        margin-bottom: 3.8rem;

        img {
            width: 100%;
        }

        @media screen and (max-width: $smartphone-small) {
            width: 16.2rem;
            margin-bottom: 2.3rem;
        }
    }

    &-addiv {
        display: block;
        width: 20.7rem;

        img {
            width: 100%;
        }

        @media screen and (max-width: $smartphone-small) {
            width: 15.1rem;
        }
    }

    &-address {
        font-size: 1.4rem;
        line-height: 2.4rem;

        p {
            font-size: 1.6rem;
        }
    }

    &-menu {
        text-transform: capitalize;

        li {
            margin-bottom: 1rem;

            span {
                cursor: pointer;
            }
        }

        a, span {
            color: $blue;
        }
    }

    &-contact {
        .phone {
            margin-bottom: 1rem;

            a {
                color: $white;
            }
        }

        .text {
            color: rgba($white, .5);
            font-size: 1.2rem;
            line-height: 1.33;
            margin-bottom: 1.2rem;
            max-width: 14rem;
        }

        .footer-contact {
            margin: 2rem 0;

            .btn-left, .btn-left:before {
                background-color: $white;
                color: $text;
                cursor: pointer;
                width: 18rem;
                text-transform: uppercase;
            }

            .btn-left:after {
                background-image: url(../images/arrow_b.svg);
                transform: translate(-50%, -50%);
            }
        }
    }

    &-corner {
        background: url(../images/bg-footer.png) 100% 0 no-repeat;
        background-size: cover;
        display: block;
        width: 30rem;
        height: 13.9rem;
        position: absolute;
        top: -1.6rem;
        right: 0;

        .linkedin {
            position: absolute;
            top: 2.7rem;
            right: 2.9rem;

            img {
                width: 2rem;
                height: 2rem;
            }
        }

        @media screen and (max-width: $smartphone-small) {
            width: 16.2rem;
            height: 7.5rem;
            top: -.8rem;

            .linkedin {
                top: 1.4rem;
                right: 1.6rem;

                img {
                    width: 1.2rem;
                    height: 1.2rem;
                }
            }
        }
    }

    @media screen and (max-width: $l-hd + 40) {
        padding: 3.4rem 11rem 4rem 7rem;
    }

    @media screen and (max-width: $hd - 100) {
        padding-left: 2.7rem;

        .container {
            flex-flow: row wrap;
        }

        .footer {
            &-logos,
            &-address,
            &-menu,
            &-contact {
                flex: 0 0 50%;
                width: 50%;
            }

            &-logos,
            &-address {
                margin-bottom: 2.5rem;
            }

            &-menu {
                margin: 2rem 0 3rem;
            }
        }
    }

    @media screen and (max-width: $tablet-small) {
        .container {
            display: block;
        }

        .footer {
            &-logos,
            &-address,
            &-menu,
            &-contact {
                width: 100%;
            }

            &-menu {
                margin: 0;
            }

            &-contact {
                margin-top: 2.5rem;

                .text, .logo-wd {
                    display: inline-block;
                }

                .text {
                    max-width: 11rem;
                    margin-bottom: 0;
                }
            }
        }
    }

    @media screen and (max-width: $smartphone-small) {
        padding-right: 4rem;
    }
}

.linkedin {
    svg {
        width: 2.5rem;
        height: 2rem;
    }
}