.col {
    padding: 0 1.5rem;

    &-12 {
        width: 100%;
        padding: 0 1.5rem;
    }

    &-6 {
        width: 50%;
        padding: 0 1.5rem;
    }

    &-5 {
        flex: 0 0 41.66666667%;
        width: 41.66666667%;
        padding: 0 1.5rem;
    }

    &-4 {
        width: 33.33333333%;
        padding: 0 1.5rem;
    }

    &-3 {
        width: 25%;
        padding: 0 1.5rem;
    }

    &-2-5 {
        width: 20%;
        padding: 0 1.5rem;
    }

    &-2 {
        width: 16.66666667%;
        padding: 0 1.5rem;
    }

    @media screen and (min-width: $smartphone-smallest) {
        &-xs {
            &-12 {
                width: 100%;
                padding: 0 1.5rem;
            }

            &-6 {
                width: 50%;
                padding: 0 1.5rem;
            }

            &-4 {
                width: 33.33333333%;
                padding: 0 1.5rem;
            }

            &-3 {
                width: 25%;
                padding: 0 1.5rem;
            }

            &-2-5 {
                width: 20%;
                padding: 0 1.5rem;
            }
        }
    }

    @media screen and (min-width: $smartphone-middle) {
        &-sm {
            &-12 {
                width: 100%;
                padding: 0 1.5rem;
            }

            &-6 {
                width: 50%;
                padding: 0 1.5rem;
            }

            &-4 {
                width: 33.33333333%;
                padding: 0 1.5rem;
            }

            &-3 {
                width: 25%;
                padding: 0 1.5rem;
            }

            &-2-5 {
                width: 20%;
                padding: 0 1.5rem;
            }
        }
    }

    @media screen and (min-width: $tablet-small) {
        &-md {
            &-12 {
                width: 100%;
                padding: 0 1.5rem;
            }

            &-6 {
                width: 50%;
                padding: 0 1.5rem;
            }

            &-4 {
                width: 33.33333333%;
                padding: 0 1.5rem;
            }

            &-3 {
                width: 25%;
                padding: 0 1.5rem;
            }

            &-2-5 {
                width: 20%;
                padding: 0 1.5rem;
            }
        }
    }

    @media screen and (min-width: $tablet) {
        &-lg {
            &-12 {
                width: 100%;
                padding: 0 1.5rem;
            }

            &-6 {
                width: 50%;
                padding: 0 1.5rem;
            }

            &-4 {
                width: 33.33333333%;
                padding: 0 1.5rem;
            }

            &-3 {
                width: 25%;
                padding: 0 1.5rem;
            }

            &-2-5 {
                width: 20%;
                padding: 0 1.5rem;
            }
        }
    }

    @media screen and (min-width: $hd) {
        &-xl {
            &-12 {
                width: 100%;
                padding: 0 1.5rem;
            }

            &-4 {
                width: 33.33333333%;
                padding: 0 1.5rem;
            }

            &-3 {
                width: 25%;
                padding: 0 1.5rem;
            }

            &-2-5 {
                width: 20%;
                padding: 0 1.5rem;
            }
        }
    }
}

.container-cols {
    padding: 0 1.5rem;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5rem;

    &-3 {
        .col-md-4 {
            .pic {
                display: inline-block;
            }

            &:nth-child(3n+2) {
               text-align: center;
            }

            &:nth-child(3n+3) {
                text-align: right;
            }
        }
    }
}