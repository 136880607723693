.dropdown {
    position: relative;

    &-list {
        background-color: $white;
        border: .1rem solid rgba($border,.7);
        border-radius: 0 0 .8rem .8rem;
        color: $text;
        margin-top: -.1rem;
        padding: 1rem 2.4rem;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 10;
        display: none;

        li {
          padding: .5rem 0;
      }
    }

    &-input {
        background-color: rgba($bg-input, .7);
        border: .1rem solid rgba($border, .7);
        border-radius: .8rem;
        font-family: $font-family-base-medium;
        font-size: 1.6rem;
        height: 4.8rem;
        padding: 0 2.4rem;
        width: 100%;
    }

    &.open {
        .dropdown {
            &-input {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
            &-list {
                display: block;
            }
        }
    }
}