.services {
    &-page {
        padding: 0 2.7rem 8rem;

        .section-services {
            padding: 0 2.7rem 8rem;
            margin: 0 -2.7rem 5.6rem;
            position: relative;

            &:before {
                background: $bg-input url(../images/bg-page.png) 0 100% no-repeat;
                border-radius: 0;
                content: '';
                display: block;
                width: 100%;
                height: calc(100% + 8.6rem);
                position: absolute;
                top: -8.6rem;
                left: 0;
                transform: none;
                z-index: -1;

                @media screen and (max-width: $tablet) {
                    height: calc(100% + 4.1rem);
                }

                @media screen and (max-width: $smartphone-middle) {
                    top: -4.1rem;
                    background-size: 9.2rem 7.3rem;
                }
            }

            &:after {
                display: none;
            }

            @media screen and (max-width: $smartphone-middle) {
                margin: 0 -1.5rem 8rem;
                padding: 0 1.5rem 5.6rem;
            }

            &_right {
                padding-left: 10rem;

                .wish-one {
                    margin-left: 2.6rem;
                }

                .or-row {
                    margin-left: 3.5rem;
                }

                .wish-two {
                    margin-left: 3.7rem;
                }

                @media screen and (max-width: $tablet) {
                    padding-left: 0;

                    &:before {
                        display: none;
                    }

                    .wish-one {
                        margin-left: 0;
                    }

                    .or-row {
                        margin-left: 0;
                    }

                    .wish-two {
                        margin-left: 0;
                    }
                }
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            padding: 0 1.5rem 4rem;
        }
    }

    &-advantages {
        h2 {
            margin: 0 auto 3rem;
            max-width: 55rem;
            text-align: center;
        }

        .btn {
            max-width: 40rem;
            margin: 0 auto;

            @media screen and (max-width: $smartphone-middle) {
                max-width: 25rem;
            }
        }

        &_one {
            color: rgba($text, .7);
            height: 100%;
            max-width: 34.3rem;
            padding-top: 1rem;
            position: relative;

            @media screen and (max-width: $tablet-small) {
                max-width: 100%;
                height: auto;
                margin-bottom: 3rem;
                padding-top: 0;
            }

            .icon-number {
                height: 6rem;
                margin-bottom: 3.8rem;
                padding-left: 3.5rem;
                position: relative;

                @media screen and (max-width: $tablet-small) {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 2.5rem;
                    padding-left: 0;
                }

                .number {
                    color: rgba($sand2, .9);
                    font-family: $font-family-base-bold;
                    font-size: 96px;
                    line-height: 24px;
                    position: absolute;
                    top: 1.2rem;
                    left: 0;

                    @media screen and (max-width: $tablet-small) {
                        margin-left: -3.5rem;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                .icon {
                    position: relative;
                }
            }

            .subtitle {
                color: $text;
                font-family: $font-family-base-medium;
                line-height: 1.125;
                margin-bottom: 2.4rem;

                @media screen and (max-width: $tablet-small) {
                    font-size: 1.6rem;
                }
            }

            .text {
                p {
                    margin-bottom: 2rem;
                }

                @media screen and (max-width: $smartphone-middle) {
                    line-height: 1.57;
                }
            }

            li {
                padding: 0 0 2rem 2.3rem;
                position: relative;

                &:before {
                    background: url(../images/angle.svg) 0 0 no-repeat;
                    background-size: cover;
                    content: '';
                    display: block;
                    width: .7rem;
                    height: 1.2rem;
                    position: absolute;
                    top: .7rem;
                    left: 0;
                }
            }

            .right-side {
                padding-left: 3rem;

                @media screen and (max-width: $tablet-small) {
                    padding-left: 0;
                }
            }

            &.line {
                padding-left: 3rem;

                @media screen and (max-width: $tablet) {
                    padding-left: 1rem;
                }

                @media screen and (max-width: $tablet-small) {
                    padding:4rem 0 0;
                }

                &:before {
                    background-color: $border;
                    content: '';
                    display: block;
                    width: .1rem;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: -1.5rem;

                    @media screen and (max-width: $tablet-small) {
                        width: 100%;
                        height: .1rem;
                        left: 0;
                    }
                }

                &:after {
                    background: url(../images/vline.png) 0 0 no-repeat;
                    content: '';
                    display: block;
                    width: 1.4rem;
                    height: 3rem;
                    position: absolute;
                    top: 50%;
                    left: -1.5rem;
                    transform: translateY(-50%);

                    @media screen and (max-width: $tablet-small) {
                        top: -.8rem;
                        left: 50%;
                        transform: translateX(-50%) rotate(90deg);
                    }
                }

                &-left {
                    &:before {
                        background-color: $border;
                        content: '';
                        display: block;
                        width: .1rem;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        right: -.75rem;

                        @media screen and (max-width: $tablet-small) {
                            width: 100%;
                            height: .1rem;
                            left: 0;
                            bottom: 0;
                            top: auto;
                            right: auto;
                        }
                    }

                    &:after {
                        background: url(../images/vline.png) 0 0 no-repeat;
                        content: '';
                        display: block;
                        width: 1.4rem;
                        height: 3rem;
                        position: absolute;
                        top: 50%;
                        right: -.75rem;
                        transform: translateY(-50%) rotate(180deg);


                        @media screen and (max-width: $tablet-small) {
                            top: auto;
                            bottom: -.8rem;
                            right: 50%;
                            transform: translateX(50%) rotate(-90deg);
                        }
                    }

                    @media screen and (max-width: $tablet-small) {
                        margin-bottom: 1rem;
                        padding-bottom: 3rem;
                    }
                }
            }

            &.flex {
                max-width: none;

                &.line {
                    &:before,
                    &:after {
                        left: -.75rem;

                        @media screen and (max-width: $tablet-small) {
                            left: 0;
                        }
                    }

                    @media screen and (max-width: $tablet-small) {
                        &:after {
                            left: 50%;
                        }
                    }
                }

                .text {
                    max-width: 40rem;
                }
            }
        }

        @media screen and (max-width: $tablet-small) {
            .scroll-wrapper,
            .tabs {
                margin: 0 -2.7rem;
                justify-content: flex-start;
            }

            .tabs {
                padding: 0 2.7rem;

                .tab {
                    white-space: nowrap;
                }
            }

            .scrollbar-outer {
                & > .scroll-content.scroll-scrollx_visible {
                    top: 0;
                    margin-top: 0;
                }

                & > .scroll-element.scroll-x {
                    display: none;
                }
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            .scrollbar-outer,
            .tabs {
                margin: 0 -1.5rem;
                justify-content: flex-start;
            }
        }
    }

    &-tab {
        margin-bottom: 3.2rem;
    }
}