.tabs a {
  cursor: pointer;
}
.react-responsive-modal-modal {
  padding: 0;
}

.iziModal {
  display: block;
}

.form-item.error .error-text {
  position: absolute;
  left: 0;
  bottom: 0;
}

.portfolio-slider-thumb .carousel-pic img {
  width: 130%;
  object-fit: unset;
}

.scrollbar-outer {
  overflow-x: auto;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-thumb {
  background: #fff;
}

.mobile-menu {
  z-index: 1111111;
}

.menu-open {
  padding: 0;
  .header {
    border-radius: 0;
  }
}

.dropdown-list {
  display: block;
  opacity: 0;
  transition: 400ms;
  z-index: -1;

  li {
    cursor: pointer;
  }
  &-focus {
    opacity: 1;
    z-index: 400;
    transition: 400ms;
  }
}

.react-responsive-modal-modal {
  display: block;
  max-width: initial;
  background-color: transparent;
  margin: 0;
}
.iziModal .iziModal-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;

}

.portfolio-top .text {
  margin-bottom:2rem;
}

.newsletter_modal {
  .react-responsive-modal-container {
    display: flex;

    justify-content: center;
    align-items: center;
  }
}
.small-modal .iziModal-wrap .modal-body {
  padding-bottom: 1rem;
}
.section-subscribe_form .btn.btn-gradient {
  margin-bottom: 2.2rem;
  width: 20rem;
}
.section-subscribe_form .input {

  flex: 0 0 33.6rem;
  width: 33.6rem;
}
.blog-info{
  margin-top: -24rem;
  @media screen and (max-width: 880px) {
    margin-top: 0;
  }

  p {
    margin-top: 3.5rem;
  }
}
@media screen and (max-width: 880px) {
  .blog-tablet {
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .iziModal .iziModal-wrap {
    position: initial;
  }
  .iziModal .iziModal-content {
  }
}


@media screen and (max-width: 707px) {
  .section-subscribe_form .input {
    margin: 0 0 1.6rem;
    width: 100%;
  }
}
