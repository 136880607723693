$black:                 #000;
$white:                 #fff;
$light-blue:            #80B4CA;
$blue:                  #247BA0;
$green:                 #34AD73;
$green2:                #37AE75;
$light-green:           #84d2b1;
$bg:                    #F5F3EF;
$bg-input:              #FCFBFA;
$text:                  #0A2239;
$border:                #eaeaea;
$red:                   #FF7878;
$sand:                  #EFECE7;
$sand2:                 #F0EDE8;
$sand3:                 #E9E4DC;
$grey:                  #c5c5c5;


$xl-hd:					1600px;
$l-hd:					1400px;
$hd:					1280px;
$desktop:				1024px;
$tablet: 				991px;
$tablet-middle: 		880px;
$tablet-small:          767px;
$smartphone:			640px;
$smartphone-middle:		575px;
$smartphone-small:		480px;
$smartphone-smallest:	375px;
