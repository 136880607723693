.contact {
    &-address,
    &-phone {
        font-size: 1.8rem;
        line-height: 1.78;
        padding-left: 9rem;
        position: relative;

        &:before {
            background: url(../images/icon-map.svg) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 5.2rem;
            height: 5.2rem;
            position: absolute;
            top: .7rem;
            left: 0;

            @media screen and (max-width: $tablet) {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        @media screen and (max-width: $hd) {
            padding-left: 7rem;
        }

        @media screen and (max-width: $tablet) {
            margin-bottom: 6rem;
            padding: 7.5rem 0 0;
            text-align: center;
        }

        @media screen and (max-width: $smartphone-middle) {
            font-size: 1.4rem;
            line-height: 1.28;
        }
    }

    &-phone {
        margin: 0 auto;
        max-width: 28.8rem;

        &:before {
            background: url(../images/icon-phone.svg) 0 0 no-repeat;
        }

        @media screen and (max-width: $tablet) {
            margin-bottom: 6rem;
        }
    }

    &-button {
        display: flex;
        justify-content: flex-end;
        padding-right: 3.2rem;
        position: relative;
        z-index: 2;

        .btn {
            width: 28.5rem;
        }

        @media screen and (max-width: $tablet) {
            justify-content: center;
        }
    }
}