.estimate {
    &-page {
        padding: 0 2.7rem 8rem;

        @media screen and (max-width: $smartphone-middle) {
            padding: 0 1.5rem 4.7rem;
        }
    }

    &-first {
        margin: 0 -2.7rem;
        padding: 0 2.7rem;
        position: relative;

        &:before {
            background: $bg-input url(../images/bg-page.png) 0 100% no-repeat;
            content: '';
            display: block;
            width: 100%;
            height: 43.3rem;
            position: absolute;
            top: -8.6rem;
            left: 0;
            z-index: -1;

            @media screen and (max-width: $tablet-small) {
                height: calc(100% + 10.6rem);
            }

            @media screen and (max-width: $smartphone-middle) {
                top: -4.1rem;
                height: calc(100% + 4.1rem);
                background-size: 9.2rem 7.3rem;
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            margin: 0 -1.5rem;
            padding: 0 1.5rem;
        }
    }

    &-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6.3rem;

        @media screen and (max-width: $hd - 180) {
            flex-flow: row wrap;
        }

        @media screen and (max-width: $tablet-small) {
            flex-direction: column;
            align-items: center;
        }

        &_caption {
            font-size: 1.8rem;
            line-height: 1.33;
            max-width: 32rem;

            @media screen and (max-width: $hd - 180) {
                flex: 0 0 100%;
                max-width: none;
                width: 100%;
                margin-bottom: 3rem;
            }
        }

        &_item {
            border-left: .1rem solid $border;
            padding: .5rem 0 2rem 6.7rem;
            position: relative;

            @media screen and (max-width: $hd) {
                padding-left: 3.7rem;
                padding-bottom: 3rem;
            }

            &:before {
                background: url(../images/vline2.png) 0 0 no-repeat;
                content: '';
                display: block;
                width: 1.5rem;
                height: 2.4rem;
                position: absolute;
                top: 50%;
                left: -.1rem;
                transform: translateY(-50%)
            }

            &-in {
                display: flex;
                padding-left: 2.7rem;
                position: relative;

                @media screen and (max-width: $tablet-small) {
                    flex-direction: column;
                    align-items: center;
                    padding-left: 0;
                    text-align: center;
                }
            }

            .number {
                color: rgba($sand3, .8);
                font-family: $font-family-base-bold;
                font-size: 9.6rem;
                line-height: 0.25;
                position: absolute;
                top: 1.3rem;
                left: 0;
            }

            span[class*="icon-"] {
                margin-right: 2.1rem;
                position: relative;

                @media screen and (max-width: $tablet-small) {
                    margin: 0 0 3rem;
                }
            }


            &.one {
                p {
                    max-width: 10.2rem;
                }

                @media screen and (max-width: $hd - 180) {
                    border-left: none;
                    padding-left: 0;

                    &:before {
                        display: none;
                    }
                }
            }

            &.two {
                p {
                    max-width: 28rem;
                }

                @media screen and (max-width: $tablet-small) {
                    border-left: none;
                    padding-top: 6.6rem;
                    padding-left: 0;

                    &:before {
                        background-color: $border;
                        background-image: none;
                        content: '';
                        display: block;
                        width: 10rem;
                        height: .1rem;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    &:after {
                        background: url(../images/vline.png) 0 0 no-repeat;
                        content: '';
                        display: block;
                        width: 1.5rem;
                        height: 2.4rem;
                        position: absolute;
                        top: -.5rem;
                        left: 50%;
                        transform: translateX(-50%) rotate(90deg);
                    }

                    .number {
                        margin-left: -3rem;
                        left: 50%;
                        transform: translateX(-50%)
                    }
                }
            }
        }
    }

    &-form {
        div[class*="col-"] {
            position: relative;
        }

        .btn {
            width: 24rem;
            height: 6rem;
            margin: 0 auto;
            padding: 1rem 3rem;
        }

        @media screen and (max-width: $tablet) {
            .form-item {
                margin: 0 auto;
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            width: 100%;
        }
    }

    &-ou {
        color: $blue;
        font-family: $font-family-base-medium;
        font-size: 1.6rem;
        line-height: 1.13;
        position: absolute;
        bottom: 3.9rem;
        right: -.9rem;

        @media screen and (max-width: $tablet) {
            position: relative;
            bottom: auto;
            right: auto;
            display: block;
            margin-bottom: 1.5rem;
            text-align: center;
        }
    }

    &-end {
        max-width: 40rem;
        margin: 5rem auto 7rem;
        text-align: center;

        h3 {
            margin-bottom: 3.2rem;
        }
    }
}