.tabs{
    border-bottom: .1rem solid $text;
    display: flex;
    justify-content: center;
    text-transform: uppercase;

    .tab {
        padding: 2.7rem 2.2rem;
        position: relative;

        &:after {
            background-color: $text;
            content: '';
            display: block;
            width: 100%;
            height: .1rem;
            position: absolute;
            bottom: -.1rem;
            left: 0;
        }
    }

    a {
        color: $text;
    }

    .active{
        &:before {
            background: url(../images/bg-tabs.png) 0 0 no-repeat;
            content: '';
            display: block;
            width: 3.2rem;
            height: 1.4rem;
            position: absolute;
            bottom: -.1rem;
            left: 50%;
            transform: translateX(-50%);
            z-index: 3;
        }

        a {
            font-family: $font-family-base-bold;
        }
    }

    &-one {
        display: none;
    }

    &-content{
        padding-top: 4.6rem;

        .tabs-one:first-child {
            display: block;
        }
    }
}