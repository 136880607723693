* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none !important;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;

    &:focus,
    &:active {
        outline: none !important;
        webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
}

html {
    font-size: $font-size-base;
    height: 100%;
}

body {
    min-height: 100%;
    background-color: $bg;
    color: $text;
    font-family: $font-family-base;
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 4.8rem 6.4rem 7.4rem;
    position: relative;

    @media screen and (max-width: $hd) {
        padding-left: 4.4rem;
        padding-right: 4.4rem;
    }

    @media screen and (max-width: $smartphone) {
        padding: 1.5rem 2rem 2.5rem;
    }

    @media screen and (max-width: $smartphone-middle) {
        font-size: 1.4rem;
        line-height: 1.29;
    }
}

a {
    color: $text;
    text-decoration: none;
    transition: all .2s linear;

    &:hover {

    }
}

img {
    display: block;
}

ul, ul li {
    list-style: none;
}

ul,ol, p {
    margin: 0;
}

section {
    position: relative;
}

figure {
    margin: 0;
}

address {
    font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin: 0;
}

h1 {
    font-family: $font-family-base-medium;
    font-size: 3.6rem;
    line-height: 1.08;
    margin-bottom: 6.8rem;
    text-transform: capitalize;

    sup {
        font-size: 1.8rem;
        top: -1.9rem;
    }

    @media screen and (max-width: $smartphone-middle) {
        font-size: 2.4rem;
        margin-bottom: 2.4rem;

        sup {
            font-size: 1.2rem;
            top: - 1.3rem;
        }
    }
}

h2 {
    font-family: $font-family-base-medium;
    font-size: 3.6rem;
    line-height: 1.08;
    margin-bottom: 3rem;

    @media screen and (max-width: $smartphone-middle) {
        font-size: 2.4rem;
    }
}

h3 {
    font-size: 2.2rem;
    line-height: 1.45;
}

.container {
    max-width: 117.1rem;
    margin: 0 auto;
}