.page {
    background-color: $white;
    box-shadow: 0 .4rem 2rem rgba($light-blue, .4);
    border-radius: 1.6rem;
    overflow: hidden;
    padding-top: 19.2rem;
    position: relative;
    z-index: 3;

    @media screen and (max-width: $smartphone-middle) {
        padding-top: 10.2rem;
    }

    &-index {
        &:before {
            background: url(../images/bg-blue.svg) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;

            @media screen and (max-width: $hd) {
                background-position: 50% 0;
            }
        }

        &:after {
            background: url(../images/bg-green.svg) 0 100% no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 100%;
            height: 65%;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .page {
            padding-top: 0;

            &:before {
                display: none;
            }
        }

        .header {
            background-color: rgba($white, .6);
            border-bottom: none;
            transition: background-color .2s linear;

            &.scroll {
                background-color: $white;
                border-bottom: .1rem solid $text;
            }
        }
    }

    &-bg {
        &:before {
            background: url(../images/bg-blue.svg) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;

            @media screen and (max-width: $hd) {
                background-position: 50% 0;
            }

            @media screen and (max-width: $smartphone-middle) {
                background-image: url(../images/bg-blue_sm.svg);
            }
        }
    }

    &-about,
    &-portfolio,
    &-project{
        &:before {
            background-color: $bg-input;
            content: '';
            display: block;
            width: 100%;
            height: 38.6rem;
            position: absolute;
            top: 10.6rem;
            left: 0;
            z-index: -2;

            @media screen and (max-width: $smartphone-middle) {
                top: 6.1rem;
            }

            @media screen and (max-width: $smartphone-small) {
                height: 22.1rem;
            }
        }

        &:after {
            background: url(../images/bg-page.png) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 15.3rem;
            height: 12.2rem;
            position: absolute;
            top: 37rem;
            left: 0;
            z-index: -1;

            @media screen and (max-width: $smartphone-middle) {
                top: 32.5rem;
            }

            @media screen and (max-width: $smartphone-small) {
                width: 9.2rem;
                height: 7.3rem;
                top: 20.9rem;
            }
        }
    }

    &-project {
        &:before {
            height: 32rem;
        }
    }

    &-portfolio {
        @media screen and (max-width: $tablet-small) {
            &:before,
            &:after {
                display: none;
            }
        }
    }

    &-expertise {
        padding: 0 2.7rem .5rem;
        margin-bottom: 8rem;
        position: relative;

        &:before {
            background: $bg-input url(../images/bg-page.png) 0 100% no-repeat;
            border-radius: 0;
            content: '';
            display: block;
            width: 100%;
            height: calc(100% + 8.6rem);
            position: absolute;
            top: -8.6rem;
            left: 0;
            transform: none;
            z-index: -1;

            @media screen and (max-width: $tablet) {
                height: calc(100% + 4.1rem);
            }

            @media screen and (max-width: $smartphone-middle) {
                top: -4.1rem;
                background-size: 9.2rem 7.3rem;
            }
        }

        h1 {
            margin-bottom: 4rem;
        }

        .intro-text {
            color: rgba($text, .7);

            .subtitle {
                color: $text;
                margin-bottom: 1.7rem;
            }
        }

        & + .services-advantages {
            margin-bottom: 9rem;
            padding: 0 2.7rem;

            @media screen and (max-width: $smartphone-middle) {
                padding: 0 1.5rem;
            }

            h2 {
                margin-bottom: 6.5rem;
                padding-bottom: 3rem;
                position: relative;

                &:before {
                    background-color: $border;
                    content: '';
                    display: block;
                    width: 40.2rem;
                    height: .1rem;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }

                &:after {
                  background: url(../images/vline.png) 0 0 no-repeat;
                  content: '';
                  display: block;
                  width: 1.4rem;
                  height: 3rem;
                  position: absolute;
                  bottom: -2rem;
                  left: 50%;
                  transform: translateX(-50%) rotate(90deg);
                }
            }

            .services-advantages_one.line:before,
            .services-advantages_one.line:after {
                display: none;
            }
        }

        &_services {
            padding: 0 2.7rem 5rem;

            @media screen and (max-width: $smartphone-middle) {
                padding: 0 1.5rem 5rem;
            }

            &-one {
                background-color: $bg-input;
                margin-bottom: 2.2rem;
                padding: 3rem 5rem 3rem 20rem;
                position: relative;

                @media screen and (max-width: $desktop) {
                    padding-right: 3rem;
                    padding-left: 22rem;
                }

                @media screen and (max-width: $tablet) {
                    padding: 14rem 3rem 3rem;
                }

                @media screen and (max-width: $smartphone-middle) {
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                }

                &:before {
                    background: url(../images/services/bg-icon.svg) 0 0 no-repeat;
                    background-size: cover;
                    content: '';
                    display: block;
                    width: 20rem;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;

                    @media screen and (max-width: $desktop) {
                        width: 22rem;
                    }

                    @media screen and (max-width: $tablet) {
                        background: $white;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 6.5rem;
                    }
                }

                .icon {
                    position: absolute;
                    top: 50%;
                    left: 3.8rem;
                    display: block;
                    width: 10rem;
                    height: 10rem;
                    transform: translateY(-50%);

                    @media screen and (max-width: $desktop) {
                        left: 5.8rem;
                    }

                    @media screen and (max-width: $tablet) {
                        top: 1.5rem;
                        left: 50%;
                        transform: translate(-50%)
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .title {
                    font-family: $font-family-base-medium;
                    margin-bottom: 2rem;
                    text-transform: uppercase;
                }

                .text {
                    color: rgba($text, .7);
                }

                .link {
                    padding-top: .5rem;

                    a {
                        background: url(../images/arrow_r.svg) 100% 50% no-repeat;
                        background-size: 1.45rem 9rem;
                        color: $blue;
                        padding-right: 2.6rem;

                        span {
                            text-decoration: underline;
                        }
                    }
                }

                &:first-child {
                    margin-top: 6.5rem;
                }

                &.service1 {
                    .title {
                        color: #68C59C;
                    }
                }
                &.service2 {
                    .title {
                        color: #51B98A;
                    }
                }
                &.service3 {
                    .title {
                        color: #3AA474;
                    }
                }
                &.service4 {
                    .title {
                        color: #458FB3;
                    }
                }
                &.service5 {
                    .title {
                        color: #1C6081;
                    }
                }
                &.service6 {
                    .title {
                        color: #14435F;
                    }
                }
                &.service7 {
                    .title {
                        color: #092A3E;
                    }
                }
            }
        }
    }
}