.icon {
    &-hands {
        background: url(../images/icon-shake.svg) 0 0 no-repeat;
        background-size: cover;
        display: block;
        width: 5rem;
        height: 5.5rem;
    }

    &-schedule {
        background: url(../images/icon-schedule.svg) 0 0 no-repeat;
        background-size: cover;
        display: block;
        width: 4.1rem;
        height: 4.8rem;
    }

    &-open {
        width: 6.2rem;
        height: 5.1rem;
    }

    &-release {
        width: 5.6rem;
        height: 5.6rem;
    }

    &-villa {
        width: 5.6rem;
        height: 5.6rem;
    }

    &-city {
        width: 5rem;
        height: 5.1rem;
    }

    &-service {
        width: 5.7rem;
        height: 5.7rem;
    }

    &-sign {
        background: url(../images/icon-sign.svg) 0 0 no-repeat;
        background-size: cover;
        display: block;
        flex: 0 0 4.4rem;
        width: 4.4rem;
        height: 4.8rem;
    }

    &-contact {
        background: url(../images/icon-contact.svg) 0 0 no-repeat;
        background-size: cover;
        display: block;
        flex: 0 0 4.6rem;
        width: 4.6rem;
        height: 4.7rem;
    }
}