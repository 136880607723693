.about {
    &-main {
        margin-bottom: 10.6rem;
        padding: 0 2.7rem;

        @media screen and (max-width: $smartphone-middle) {
            margin-bottom: 6rem;
            padding: 0 1.5rem;
        }
    }

    &-pic {
        width: 100%;
        max-width: 37rem;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 1.6rem;
        margin: -1.7rem 0 0;
        padding: 2rem 9rem 2.6rem 2rem;
        position: relative;
        overflow: hidden;

        &:before {
            background-color: $white;
            content: '';
            display: block;
            width: calc(100% - 14.8rem);
            height: 1.7rem;
            position: absolute;
            top: 0;
            left: 0;
        }

        @media screen and (max-width: $smartphone-middle) {
            padding: 2rem 8rem 2rem 1.5rem;

            &:before {
                width: calc(100% - 10.8rem);
            }
        }

        .corner {
            background: url(../images/bg-about3.png) 0 0 no-repeat;
            background-size: cover;
            width: 14.8rem;
            height: 12.8rem;
            position: absolute;
            top: 0;
            right: 0;

            img {
                position: absolute;
                top: 2.3rem;
                right: 2.7rem;
                width: 1.8rem;
                height: 1.8rem;
            }

            @media screen and (max-width: $smartphone-middle) {
                width: 10.8rem;
                height: 8.8rem;

                img {
                    top: 1.5rem;
                    right: 1.5rem;
                }
            }
        }
    }

    &-one {
        background-color: $white;
        box-shadow: .2rem .2rem 1rem rgba($black, .1);
        border-radius: 1.6rem;
        color: rgba($text, .7);
        cursor: pointer;
        margin: 0 1.5rem 3rem;
        max-width: 37rem;
        overflow: hidden;

        @media screen and (max-width: $tablet) {
            display: block;
            position: relative;
        }
    }

    &-name {
        color: $text;
        font-family: $font-family-base-medium;
        font-size: 2.4rem;
        line-height: 1.08;
        margin-bottom: 1.6rem;
        max-width: 24.4rem;
        position: relative;

        @media screen and (max-width: $smartphone-middle) {
            font-size: 1.8rem;
            margin-bottom: .8rem;
            width: 100%;
        }
    }

    &-job {
        color: $blue;
        font-family: $font-family-base-medium;
        font-size: 1.8rem;
        line-height: 1.11;
        position: relative;

        @media screen and (max-width: $smartphone-middle) {
            font-size: 1.4rem;
            width: 100%;
        }
    }

    &-text {
        position: relative;

        @media screen and (max-width: $smartphone-middle) {
            line-height: 1.71;
        }
    }

    &-map {
        height: 62.6rem;
        margin: -4.5rem 0 -1.7rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media screen and (max-width: $smartphone-middle) {
            height: 35rem;
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -1.5rem;
    }
}