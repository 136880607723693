.btn {
    background: none;
    border-radius: .8rem;
    color: $white;
    display: block;
    font-family: 'Gotham HTF Medium';
    font-size: 1.8rem;
    line-height: 1.125;
    padding: 2.7rem 2rem;
    height: 5.8rem;
    transition: all .3s ease-out;
    text-align: center;
    position: relative;

    &:hover,
    &:focus,
    &:active,
    &.focus {
        outline: none !important;
        box-shadow: none;
        text-decoration: none;
    }

    &:disabled,
    &.disabled {
        opacity: .5;
    }

    @media screen and (max-width: $smartphone-middle) {
        font-size: 1.4rem;
        width: 100%;
        height: 4.8rem;
        padding: .7rem 2rem;
    }

    &-blue {
        background-color: $text;
    }

    &-gradient {
        background: $light-green;
        background: -moz-linear-gradient(left, $light-green 0%, $green 100%);
        background: -webkit-linear-gradient(left, $light-green 0%, $green 100%);
        background: linear-gradient(to right, $light-green 0%, $green 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#84d2b1', endColorstr='#34ad73',GradientType=1 );
        height: 7.2rem;

        @media screen and (max-width: $smartphone-middle) {
            height: 4.8rem;
        }

        &:after {
            background: url(../images/arrow_w.svg) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 2rem;
            height: 1.2rem;
            position: absolute;
            top: 50%;
            right: 1rem;
            opacity: 0;
            transform: translate(-50%, -50%);
            transition: all .2s linear;

            @media screen and (max-width: $smartphone-middle) {
                display: none;
            }
        }

        &:hover {
            &:after {
                opacity: 1;
            }
        }

        &:active {
            background: $green;
        }

        &_blue {
            background: #247ba0; /* Old browsers */
            background: -moz-linear-gradient(left,  #247ba0 0%, #0a2239 100%); /* FF3.6-15 */
            background: -webkit-gradient(linear, left top, right top, color-stop(0%,#247ba0), color-stop(100%,#0a2239)); /* Chrome4-9,Safari4-5 */
            background: -webkit-linear-gradient(left,  #247ba0 0%,#0a2239 100%); /* Chrome10-25,Safari5.1-6 */
            background: -o-linear-gradient(left,  #247ba0 0%,#0a2239 100%); /* Opera 11.10-11.50 */
            background: -ms-linear-gradient(left,  #247ba0 0%,#0a2239 100%); /* IE10 preview */
            background: linear-gradient(to right,  #247ba0 0%,#0a2239 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#247ba0', endColorstr='#0a2239',GradientType=1 ); /* IE6-9 */
        }
    }

    &-left {
        span {
            position: relative;
        }

        &:before {
            background-color: $text;
            border-radius: .8rem;
            content: '';
            display: block;
            width: 6rem;
            height: 100%;
            transform: skew(-17deg);
            position: absolute;
            top: 0;
            left: -.7rem;
            transition: all .2s linear;
        }

        &:after {
            background: url(../images/arrow_w.svg) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 2rem;
            height: 1.2rem;
            position: absolute;
            top: 50%;
            left: 2rem;
            opacity: 0;
            transform: translate(-50%, -50%) rotate(180deg);
            transition: all .2s linear;

            @media screen and (max-width: $smartphone-middle) {
                display: none;
            }
        }

        &:hover {
            &:before {
                transform: skew(0deg);
            }

            &:after {
                opacity: 1;
            }
        }

        &:active {
            background: $blue;

            &:before {
                background-color: $blue;
            }
        }
    }

    &-right {
        span {
            position: relative;
        }

        &:before {
            background: -moz-linear-gradient(left,  rgba(62,178,123,0) 0%, rgba(60,177,121,1) 22%, rgba(52,173,115,1) 100%);
            background: -webkit-linear-gradient(left,  rgba(62,178,123,0) 0%,rgba(60,177,121,1) 22%,rgba(52,173,115,1) 100%);
            background: linear-gradient(to right,  rgba(62,178,123,0) 0%,rgba(60,177,121,1) 22%,rgba(52,173,115,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003eb27b', endColorstr='#34ad73',GradientType=1 );
            border-radius: 0 1.4rem .8rem 0;
            content: '';
            display: block;
            width: 6rem;
            height: 100%;
            transform: skew(-29deg);
            position: absolute;
            top: 0;
            right: -1.7rem;
            transition: all .2s linear;
        }

        &:after {
            background: url(../images/arrow_w.svg) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 2rem;
            height: 1.2rem;
            position: absolute;
            top: 50%;
            right: -1rem;
            opacity: 0;
            transform: translate(-50%, -50%);
            transition: all .2s linear;

            @media screen and (max-width: $smartphone-middle) {
                display: none;
            }
        }

        &:hover {
            &:before {
                border-top-right-radius: .8rem;
                transform: skew(0deg);
            }

            &:after {
                opacity: 1;
            }
        }

        &:active {
            &:before {
                background-color: $green;
            }
        }
    }

    &-download {
        span {
            display: inline-block;
            padding-left: 2.9rem;
            position: relative;

            &:before {
                background: url(../images/arrow_w.svg) 0 0 no-repeat;
                background-size: cover;
                content: '';
                display: block;
                width: 1.92rem;
                height: 1.198rem;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }

    &-all {
        span {
            display: inline-block;
            padding-left: 2.9rem;
            position: relative;

            &:before {
                background: url(../images/arrow_w.svg) 0 0 no-repeat;
                background-size: cover;
                content: '';
                display: block;
                width: 1.92rem;
                height: 1.198rem;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }

        &:hover {
            &:after {
                display: none;
            }
        }
    }
}

a.btn {
    line-height: 5.8rem;
    padding: 0 2rem;

    &-gradient {
        line-height: 7.2rem;
    }

    @media screen and (max-width: $smartphone-middle) {
        line-height: 4.8rem;
    }
}
