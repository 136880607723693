.portfolio {
    &-page {
        padding: 0 2.7rem 4.4rem;

        @media screen and (max-width: $smartphone-middle) {
            padding: 0 1.5rem 4.4rem;
        }

        &_one {
            font-size: 1.6rem;
            padding: 0 2.7rem;
            @media screen and (max-width: $smartphone-middle) {
                padding: 0 1.5rem;
            }

            h1 {
                max-width: 50rem;
                margin-bottom: 1.6rem;
                text-transform: none;
            }

            .info-link {
                font-family: $font-family-base-medium;
                text-decoration: underline;
            }
        }
    }

    &-first {
        margin: 0 -2.7rem;
        padding: 0 2.7rem;
        position: relative;

        &:before {
            background: $bg-input url(../images/bg-page.png) 0 100% no-repeat;
            content: '';
            display: block;
            width: 100%;
            height: calc(100% + 16.35rem);
            position: absolute;
            top: -8.6rem;
            left: 0;
            z-index: -1;

            @media screen and (max-width: $tablet-small) {
                height: calc(100% + 10.6rem);
            }

            @media screen and (max-width: $smartphone-middle) {
                top: -4.1rem;
                height: calc(100% + 4.1rem);
                background-size: 9.2rem 7.3rem;
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            margin: 0 -1.5rem;
            padding: 0 1.5rem;
        }
    }

    &-top {
        line-height: 1.63;
        width: 85rem;
        margin: 0 auto 11.5rem;
        text-align: center;

        .scrollbar-outer > .scroll-element.scroll-x {
            display: none;
        }

        @media screen and (max-width: $tablet) {
            margin-bottom: 11rem;
            width: 100%;

            .scroll-wrapper {
                margin: 0 -2.7rem;
            }

            .scrollbar-outer > .scroll-content.scroll-scrollx_visible {
                top: 0;
                margin-top: 0;
                padding: 0 2.7rem;
            }

            .row {
                flex-wrap: nowrap;
            }

            &_item {
                margin-bottom: 4.8rem;
                width: 20rem;
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            margin-bottom: 1rem;

            .scroll-wrapper {
                margin: 0 -1.5rem;
            }

            .scrollbar-outer > .scroll-content.scroll-scrollx_visible {
                padding: 0 1.5rem;
            }
        }

        &_icon {
            display: flex;
            justify-content: center;
            height: 5.7rem;
            margin-bottom: 2rem;
        }

        &_open {
            .text {
                max-width: 7.5rem;
                margin: 0 auto;

                @media screen and (max-width: $smartphone-middle) {
                    max-width: none;
                }
            }
        }

        &_release {
            .text {
                max-width: 10rem;
                margin: 0 auto;

                @media screen and (max-width: $smartphone-middle) {
                    max-width: none;
                }
            }
        }

        &_city {
            .text {
                max-width: 33.5rem;
                margin: 0 auto;

                @media screen and (max-width: $smartphone-middle) {
                    max-width: 14.5rem;
                }
            }
        }
    }

    &-tabs {
        @media screen and (max-width: $tablet-small) {
            .scroll-wrapper,
            .tabs {
                margin: 0 -2.7rem;
                justify-content: flex-start;
            }

            .scrollbar-outer {
                & > .scroll-content.scroll-scrollx_visible {
                    top: 0;
                    margin-top: 0;
                }

                & > .scroll-element.scroll-x {
                    display: none;
                }
            }
        }

        @media screen and (max-width: $smartphone-middle) {
            .scroll-wrapper,
            .tabs {
                margin: 0 -1.5rem;
            }
        }
    }

    &-one {
        background-color: $white;
        box-shadow: .2rem .2rem 1rem rgba($black, .1);
        border-radius: 1.6rem;
        font-family: $font-family-base-medium;
        margin-bottom: 4rem;
        position: relative;

        .counter {
            background: url(../images/bg-about.png) 0 0 no-repeat;
            background-size: cover;
            color: rgba($white,.6);
            display: block;
            font-size: 1.8rem;
            line-height: 1.11;
            width: 16.5rem;
            height: 14.5rem;
            position: absolute;
            top: -1.7rem;
            right: -1.7rem;

            span {
                background: url(../images/icon-camera.svg) 100% 50% no-repeat;
                background-size: 2rem 1.8rem;
                display: block;
                position: absolute;
                top: 4.5rem;
                right: 3rem;
                padding-right: 2.7rem;
                line-height: 1.8rem;

                @media screen and (max-width: $smartphone-middle) {
                    background-size: 1rem .9rem;
                    top: 2rem;
                    right: 1.5rem;
                    padding-right: 1.3rem;
                }
            }

            @media screen and (max-width: $smartphone-middle) {
                font-size: 1.2rem;
                width: 8rem;
                height: 7rem;
                top: -.9rem;
                right: -.9rem;
            }
        }

        &_pic {
            width: 100%;
            height: 36rem;

            @media screen and (max-width: $tablet-middle) {
                height: 28rem;
            }

            @media screen and (max-width: $tablet-small) {
                height: 36rem;
            }

            @media screen and (max-width: $smartphone-middle) {
                height: 28rem;
            }

            @media screen and (max-width: $smartphone-small) {
                height: 16.7rem;
            }

            img {
                border-top-left-radius: 1.6rem;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &_name {
            display: flex;
            align-items: center;
            height: 9.4rem;
            line-height: 1.125;
            padding: 1rem 4rem;

            @media screen and (max-width: $smartphone-middle) {
                height: 7.4rem;
                padding: .5rem 1.5rem;
            }
        }
    }

    &-slider {
        &-big {
            margin: 7.1rem 0 1.7rem;
            position: relative;

            @media screen and (max-width: $smartphone-middle) {
                margin-top: 6.1rem;
            }

            .owl {
                &-stage-outer {
                    overflow: hidden;

                    .item {
                        padding: 0 8.5rem;

                        @media screen and (max-width: $tablet) {
                            padding: 0;
                        }
                    }
                }

                &-nav {
                    button.owl {
                        &-prev,
                        &-next {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%) skewX(-22deg);

                            @media screen and (max-width: $tablet) {
                                position: relative;
                                top: auto;
                                transform: skewX(-22deg);
                            }

                            @media screen and (max-width: $smartphone-middle) {
                                &:before {
                                    width: 1.9rem;
                                    height: 1.2rem;
                                }
                            }
                        }

                        &-next {
                            left: auto;
                            right: 0;

                            @media screen and (max-width: $tablet) {
                                background: none;

                                &:before {
                                    background-image: url(../images/arrow_b.svg);
                                    transform: translate(-50%, -50%) skewX(22deg) rotate(180deg);
                                }
                            }
                        }
                    }

                    @media screen and (max-width: $tablet) {
                        position: absolute;
                        top: -6.5rem;
                        left: 0;
                        width: 100%;
                        height: 5.5rem;
                        display: flex;
                        justify-content: center;
                    }

                    @media screen and (max-width: $smartphone-middle) {
                        top: -4.5rem;
                        height: 4.5rem;
                    }
                }
            }
        }

        &-thumb {
            margin-bottom: 8rem;

            .carousel-pic {
                border-radius: 1.6rem;
                position: relative;
                height: 27.4rem;
                width: 34rem;
                overflow: hidden;
                opacity: .2;
                transform: skewX(-22deg);

                @media screen and (max-width: $tablet) {
                    width: 24.6rem;
                    height: 20rem;
                }

                @media screen and (max-width: $tablet-small) {
                    width: 18.5rem;
                    height: 15rem;
                }

                img {
                    width: auto;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%) skewX(22deg);
                }
            }

            .active {
                .carousel-pic {
                    opacity: 1;
                }
            }

            .current {
                .carousel-pic {
                    border: .2rem solid $green;
                }
            }
        }
    }

    &-big {
        width: 100%;
        max-width: 97rem;
        height: 60rem;
        border-radius: 1.6rem;
        margin: 0 auto;
        overflow: hidden;

        @media screen and (max-width: $tablet) {
            height: 45rem;
        }

        @media screen and (max-width: $tablet-small) {
            height: 36rem;
        }

        @media screen and (max-width: $smartphone-middle) {
            height: 29.6rem;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

}

.back-link {
    line-height: 2;
    margin-bottom: 2.4rem;

    a {
        color: $blue;
        display: inline-block;
        padding-left: 2.5rem;
        position: relative;

        &:before {
            background: url(../images/icon-back.svg) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 1.6rem;
            height: 1rem;
            position: absolute;
            top: 1.2rem;
            left: 0;
        }

        span {
            text-decoration: underline;
        }
    }
}