 @font-face {
  font-family: 'Gotham HTF Bold';
  src: url('../fonts/gothamhtf-bold-webfont.eot');
  src: url('../fonts/gothamhtf-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gothamhtf-bold-webfont.woff2') format('woff2'),
  url('../fonts/gothamhtf-bold-webfont.woff') format('woff'),
  url('../fonts/gothamhtf-bold-webfont.ttf') format('truetype'),
  url('../fonts/gothamhtf-bold-webfont.svg#gotham_htfbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham HTF Book';
  src: url('../fonts/gothamhtf-book-webfont.eot');
  src: url('../fonts/gothamhtf-book-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gothamhtf-book-webfont.woff2') format('woff2'),
  url('../fonts/gothamhtf-book-webfont.woff') format('woff'),
  url('../fonts/gothamhtf-book-webfont.ttf') format('truetype'),
  url('../fonts/gothamhtf-book-webfont.svg#gotham_htfbook') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham HTF Book Italic';
  src: url('../fonts/gothamhtf-bookitalic-webfont.eot');
  src: url('../fonts/gothamhtf-bookitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gothamhtf-bookitalic-webfont.woff2') format('woff2'),
  url('../fonts/gothamhtf-bookitalic-webfont.woff') format('woff'),
  url('../fonts/gothamhtf-bookitalic-webfont.ttf') format('truetype'),
  url('../fonts/gothamhtf-bookitalic-webfont.svg#gotham_htfbook_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham HTF Light';
  src: url('../fonts/gothamhtf-light-webfont.eot');
  src: url('../fonts/gothamhtf-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gothamhtf-light-webfont.woff2') format('woff2'),
  url('../fonts/gothamhtf-light-webfont.woff') format('woff'),
  url('../fonts/gothamhtf-light-webfont.ttf') format('truetype'),
  url('../fonts/gothamhtf-light-webfont.svg#gotham_htflight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham HTF Light Italic';
  src: url('../fonts/gothamhtf-lightitalic-webfont.eot');
  src: url('../fonts/gothamhtf-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gothamhtf-lightitalic-webfont.woff2') format('woff2'),
  url('../fonts/gothamhtf-lightitalic-webfont.woff') format('woff'),
  url('../fonts/gothamhtf-lightitalic-webfont.ttf') format('truetype'),
  url('../fonts/gothamhtf-lightitalic-webfont.svg#gotham_htflight_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham HTF Medium';
  src: url('../fonts/gothamhtf-medium-webfont.eot');
  src: url('../fonts/gothamhtf-medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gothamhtf-medium-webfont.woff2') format('woff2'),
  url('../fonts/gothamhtf-medium-webfont.woff') format('woff'),
  url('../fonts/gothamhtf-medium-webfont.ttf') format('truetype'),
  url('../fonts/gothamhtf-medium-webfont.svg#gotham_htfmedium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham HTF Thin';
  src: url('../fonts/gothamhtf-thin-webfont.eot');
  src: url('../fonts/gothamhtf-thin-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/gothamhtf-thin-webfont.woff2') format('woff2'),
  url('../fonts/gothamhtf-thin-webfont.woff') format('woff'),
  url('../fonts/gothamhtf-thin-webfont.ttf') format('truetype'),
  url('../fonts/gothamhtf-thin-webfont.svg#gotham_htfthin') format('svg');
  font-weight: normal;
  font-style: normal;
}


$font-family-base:             'Gotham HTF Book', Arial, Helvetica, sans-serif;
$font-family-base-bold:        'Gotham HTF Bold', sans-serif;
$font-family-base-medium:      'Gotham HTF Medium', sans-serif;
$font-family-second:           Arial, Helvetica, sans-serif;

$font-size-base:      10px;

$font-lighter:        200;
$font-light:          300;
$font-regular:        400;
$font-medium:         500;
$font-semibold:       600;
$font-bold:           700;
$font-black:          800;
$font-extrabold:      900;

.bold {
    font-family: $font-family-base-bold;
}
